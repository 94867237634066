import type { Document } from "@appia/api"

export const getFileExtension = (name: Document["name"]): string => {
  const parts = name.split(".")
  return parts[parts.length - 1].toLowerCase()
}

const fileExtensionOrder = ["eml", "msg", "pdf", "docx", "xlsx", "ppt"]
const KI_COMPANY_NAME = "Ki"
const HEADSHEET_DOCUMENT_TYPE = "HS"

export const compareByFileExtension = (
  { name: doc1 }: Document,
  { name: doc2 }: Document,
): -1 | 0 | 1 => {
  const doc1Type = getFileExtension(doc1)
  const doc1Idx = fileExtensionOrder.indexOf(doc1Type)

  const doc2Type = getFileExtension(doc2)
  const doc2Idx = fileExtensionOrder.indexOf(doc2Type)

  // doc1 had an extension we don't recognise; send it to the end
  if (doc1Idx === -1) return 1
  // doc2 had an extension we don't recognise; send it to the end
  else if (doc2Idx === -1) return -1
  // doc1 came first in the extension order; put it first
  else if (doc1Idx < doc2Idx) return -1
  // doc2 came first in the extension order; put it first
  else if (doc1Idx > doc2Idx) return 1
  // The documents have the same extension, so just order them alphabetically
  else if (doc1 < doc2) return -1
  else return 1
}

export const compareByCreatedAt = (
  { createdAt: doc1 }: Document,
  { createdAt: doc2 }: Document,
): -1 | 0 | 1 => (doc1 < doc2 ? -1 : doc1 > doc2 ? 1 : 0)

const sanitiseFileName = (fileName: string): string => {
  const invalidChars = /[<>:"/\\|?*]/g
  return fileName.replace(invalidChars, "").trim()
}

export const generateDocumentName = (
  britPolicyReference: string | null,
  insuredCompanyName: string | null,
  date: string | null,
  documentVersion: number,
  documentType = "pdf",
): string => {
  const maxLength = 255
  const baseName = `${KI_COMPANY_NAME} - ${HEADSHEET_DOCUMENT_TYPE} - ${
    insuredCompanyName || ""
  } - ${britPolicyReference || ""} - ${
    date || ""
  } - V${documentVersion}.${documentType}`
  const sanitisedName = sanitiseFileName(baseName)

  if (sanitisedName.length > maxLength) {
    return (
      sanitisedName.substring(0, maxLength - documentType.length - 1) +
      "." +
      documentType
    )
  }

  return sanitisedName
}
