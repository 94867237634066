import { AxiosInstance, AxiosPromise } from "axios"
import { Recipient } from "./Recipients"
import { Endorsement } from "../Endorsement"

export interface ReferDetails {
  emails: Recipient["email"][]
  body: string
  subject: string
  referrer: string
  insuredName: string
}

export const referToPu = (
  client: AxiosInstance,
  endorsementId: Endorsement["id"],
  referDetails: ReferDetails,
): AxiosPromise =>
  client.post(`/api/endorsements/${endorsementId}/pu/refer`, referDetails)
