import { FC, ReactNode, useId, useState } from "react"
import { Navigate, useParams } from "react-router-dom"

import { getKiHeadersheetData } from "@appia/api"
import * as RD from "@appia/remote-data"
import * as Sentry from "@sentry/react"

import { useGetKiQuote, useGetPBQADocuments, useGetPBQAOverview } from "src/swr"

import {
  Button,
  Card,
  ChevronRightIcon,
  DownloadIcon,
  DuplicateIcon,
  Link,
  Toast,
} from "@appia/ui-components"
import ContractModal from "../../components/ContractModal"

import DefinitionList from "src/components/DefinitionList"
import ErrorMessage from "src/components/ErrorMessage"
import Loading from "src/components/Loading"
import PolicyReferencesTable from "src/components/PolicyReferencesTable"
import ScreenTemplate from "src/templates/ScreenTemplate"
import ToastViewport from "src/components/ToastViewport"

import { logButtonClick, logPBQAExport, useLogPageView } from "src/amplitude"
import {
  prettyPrintDateString,
  prettyPrintList,
  prettyPrintSnakeCase,
  prettyPrintUpperSnakeCase,
} from "src/utils/prettyPrinters"

import useApiClient from "src/contexts/ApiClientContext"
import useDocumentTitle from "src/hooks/useDocumentTitle"
import useGetPBQASyndicates from "ReviewPBQA/useGetPBQASyndicates"
import { PageNameContext } from "src/contexts/PageNameContext"
import ContractDocuments from "../../components/ContractDocuments"

const PAGE_NAME = "View PBQA slip"
const KI_SYNDICATE_CODE = 1618

const ViewPBQAScreen: FC = () => {
  const { id } = useParams<{ id: string }>()

  if (id === undefined) {
    throw new Error("Missing pbqaId in ViewPBQA screen")
  }

  useLogPageView({ pageName: PAGE_NAME })

  const apiClient = useApiClient()

  const { request: documentsRequest } = useGetPBQADocuments(id)

  const { request: overviewRequest } = useGetPBQAOverview(id)
  const assuredName =
    RD.isSuccess(overviewRequest) && overviewRequest.data.assuredName
  const policyId =
    RD.isSuccess(overviewRequest) && overviewRequest.data.policyId
  const riskCodes = RD.isSuccess(overviewRequest)
    ? overviewRequest.data.riskCodes
    : []

  const policyReferences = RD.isSuccess(overviewRequest)
    ? overviewRequest.data.policyReferences
    : []

  const { request: kiQuoteRequest } = useGetKiQuote(id)
  const kiQuote = RD.isSuccess(kiQuoteRequest) ? kiQuoteRequest.data : null

  useDocumentTitle((assuredName ? `${assuredName} | ` : "") + "PBQA details")

  const [toastType, setToastType] = useState<Toast.ToastType>("success")
  const [toastMessage, setToastMessage] = useState<ReactNode>(null)
  const toastState = Toast.useToastState()

  const setAndTriggerToast = (
    toastType: Toast.ToastType,
    toastMessage: ReactNode,
  ): void => {
    setToastType(toastType)
    setToastMessage(toastMessage)
    toastState.triggerToast()
  }

  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const selectedSyndicatesReq = useGetPBQASyndicates(id)
  const selectedSyndicates = RD.isSuccess(selectedSyndicatesReq)
    ? selectedSyndicatesReq.data
    : []
  const hasKiOnSlip = selectedSyndicates.some(
    syndicate => syndicate.code === KI_SYNDICATE_CODE,
  )
  const referencesLabelId = useId()

  return (
    <PageNameContext.Provider value={PAGE_NAME}>
      <ScreenTemplate
        pageTitle="View PBQA"
        layout={{ type: "regular", backPath: "/pbqa" }}
      >
        {RD.match(
          overviewRequest,

          <Loading />,

          <Loading />,

          ({
            broker,
            completedSurveyId,
            completedSurveySlug,
            inceptionDate,
            assuredName,
          }) => (
            <>
              <div className="mb-4 flex w-full items-start justify-between gap-8">
                <h2 className="text-3xl">{assuredName}</h2>
              </div>

              <Card className="mb-2 flex flex-wrap items-start justify-between gap-8">
                {kiQuote ? (
                  <DefinitionList
                    items={[
                      {
                        label: "Type",
                        value: prettyPrintSnakeCase(kiQuote.riskType),
                      },
                      {
                        label: "Group class",
                        value: kiQuote.groupClass
                          ? prettyPrintUpperSnakeCase(kiQuote.groupClass)
                          : null,
                      },
                      {
                        label: "Risk Codes",
                        value: prettyPrintList(riskCodes),
                      },
                      { label: "Broker", value: broker.name },
                      {
                        label: "Inception date",
                        value:
                          inceptionDate === null
                            ? null
                            : prettyPrintDateString(inceptionDate),
                      },
                      {
                        label: "Quote ID",
                        value:
                          kiQuote.quoteId === null
                            ? null
                            : `${kiQuote.quoteId}`,
                      },
                    ]}
                  />
                ) : (
                  <DefinitionList
                    items={[
                      { label: "Type", value: completedSurveySlug },
                      { label: "Broker", value: broker.name },
                      {
                        label: "Inception date",
                        value:
                          inceptionDate === null
                            ? null
                            : prettyPrintDateString(inceptionDate),
                      },
                    ]}
                  />
                )}

                <div className="flex w-full flex-wrap items-start justify-end gap-4">
                  <Link
                    data-cy="export-pbqa"
                    theme="night"
                    style="outlined"
                    label="Export PBQA"
                    icon={{ position: "right", icon: <DownloadIcon /> }}
                    href={`${window.HOST}/api/pbqa/${id}/surveys/${completedSurveyId}/export`}
                    target="_blank"
                    download
                    onClick={() => {
                      logPBQAExport({ pbqaId: id, surveyId: completedSurveyId })

                      logButtonClick({
                        buttonName: "Export PBQA",
                        containerName: "Main",
                        pageName: PAGE_NAME,
                        linkHref: `${window.HOST}/api/pbqa/${id}/surveys/${completedSurveyId}/export`,
                      })
                    }}
                  />

                  <Link
                    theme="night"
                    style="outlined"
                    label="Review"
                    aria-label="Review PBQA"
                    href={`/pbqa/review/${id}`}
                    onClick={() => {
                      logButtonClick({
                        buttonName: "Review PBQA",
                        containerName: "Main",
                        pageName: PAGE_NAME,
                        linkHref: `/pbqa/review/${id}`,
                      })
                    }}
                  />

                  {kiQuote && (
                    <Button
                      disabled={hasKiOnSlip ? false : true}
                      key="copy"
                      label="Copy headersheet data"
                      icon={{ position: "right", icon: <DuplicateIcon /> }}
                      style="outlined"
                      theme="night"
                      onClick={async () => {
                        logButtonClick({
                          buttonName: "Copy headersheet to clipboard",
                          containerName: "Main",
                          pageName: PAGE_NAME,
                        })

                        try {
                          const { data: text } = await getKiHeadersheetData(
                            apiClient,
                            id,
                            completedSurveyId,
                          )

                          navigator.clipboard.writeText(text)

                          setToastType("success")
                          setToastMessage("Successfully copied")
                          toastState.triggerToast()
                        } catch (e) {
                          if (e instanceof Error) {
                            Sentry.captureException(e)

                            setToastType("error")
                            setToastMessage("Failed to copy headersheet data")
                            toastState.triggerToast()
                          }
                        }
                      }}
                    />
                  )}

                  <Button
                    theme="pop"
                    style="filled"
                    label="Process"
                    aria-label="Process Contract"
                    icon={{
                      position: "right",
                      icon: <ChevronRightIcon />,
                    }}
                    onClick={() => {
                      setModalOpen(true)
                      logButtonClick({
                        buttonName: "Process Contract",
                        containerName: "Main",
                        pageName: PAGE_NAME,
                      })
                    }}
                  />
                </div>
              </Card>

              <ContractDocuments
                documentsRequest={documentsRequest}
                pageName={PAGE_NAME}
              />

              <section className="pt-4" aria-labelledby={referencesLabelId}>
                <h3 id={referencesLabelId} className="mb-2 text-xl">
                  References
                </h3>

                <section className="mb-4 flex w-full items-start justify-between gap-8 overflow-auto">
                  <PolicyReferencesTable
                    policyReferences={policyReferences}
                    showWrittenLineSize
                  />
                </section>
              </section>

              <Toast.Toast
                type={toastType}
                message={toastMessage}
                open={toastState.open}
                onOpenChange={toastState.onOpenChange}
              />

              <ToastViewport />

              {modalOpen &&
                (policyId ? (
                  <ContractModal
                    isOpen={modalOpen}
                    onClose={() => setModalOpen(false)}
                    onSuccess={() => setModalOpen(false)}
                    contractPolicyId={policyId}
                    triggerToast={setAndTriggerToast}
                  />
                ) : (
                  // TODO: PP-112 - implement proper validation
                  <Card>
                    <ErrorMessage message="No Policy ID Found. Failed to start Contract Process" />
                  </Card>
                ))}
            </>
          ),

          error => {
            const status = error.response?.status
            return status === 404 ? (
              <Navigate replace to="/404" />
            ) : (
              <Card>
                <ErrorMessage
                  message="Failed to load PBQA slip:"
                  error={error}
                />
              </Card>
            )
          },
        )}
      </ScreenTemplate>
    </PageNameContext.Provider>
  )
}

export default ViewPBQAScreen
