import {
  ApiError,
  ContractDetails,
  ContractResponse,
  ContractSectionItem,
  ReferenceSplit,
  SyndicateLine,
} from "@appia/api"
import * as RD from "@appia/remote-data"

export const hasKiLines = (contractDetails: ContractDetails): boolean =>
  contractDetails.contract.sections.some(section =>
    section.lines.some(line => line.nominatedSyndicateId === 1618),
  )

export const hasKiLinesContractResponse = (
  contractResponse: ContractResponse,
): boolean =>
  contractResponse.sections.some(section =>
    section.lines.some(line => line.nominatedSyndicateId === 1618),
  )

export const filterKiLines = (
  contractDetails: ContractDetails,
): ContractDetails => {
  const filteredSections = contractDetails.contract.sections.map(section => ({
    ...section,
    lines: section.lines.filter(line => line.nominatedSyndicateId === 1618),
  }))

  return {
    ...contractDetails,
    contract: {
      ...contractDetails.contract,
      sections: filteredSections,
    },
  }
}

export const filterKiLinesContractResponse = (
  contractResponse: ContractResponse,
): ContractResponse => {
  const filteredSections = contractResponse.sections.map(section => ({
    ...section,
    lines: section.lines.filter(line => line.nominatedSyndicateId === 1618),
  }))

  return {
    ...contractResponse,
    sections: filteredSections,
  }
}

export const filterKiLinesReferenceSplit = (
  section: ContractSectionItem,
): ReferenceSplit[] => {
  return section.lines
    .filter(line => line.nominatedSyndicateId === 1618)
    .flatMap(line => line.syndicateData?.syndicateData?.referenceSplit || [])
}

export const getKiContractData = (
  contractReq: RD.RemoteData<ApiError, ContractDetails>,
): { isKiOnSlip: boolean; kiContractDataOnly: ContractDetails | null } => {
  if (!RD.isSuccess(contractReq)) {
    return { isKiOnSlip: false, kiContractDataOnly: null }
  }

  const isKiOnSlip = hasKiLines(contractReq.data)
  const kiContractDataOnly = isKiOnSlip ? filterKiLines(contractReq.data) : null

  return { isKiOnSlip, kiContractDataOnly }
}

export const getKiContractResponseData = (
  contractReq: RD.RemoteData<ApiError, ContractResponse>,
): { isKiOnSlip: boolean; kiContractDataOnly: ContractResponse | null } => {
  if (!RD.isSuccess(contractReq)) {
    return { isKiOnSlip: false, kiContractDataOnly: null }
  }

  const isKiOnSlip = hasKiLinesContractResponse(contractReq.data)
  const kiContractDataOnly = isKiOnSlip
    ? filterKiLinesContractResponse(contractReq.data)
    : null

  return { isKiOnSlip, kiContractDataOnly }
}

export const separateKiContractsByBritReference = (
  originalContractResponse: ContractResponse,
): ContractResponse[] => {
  const allKiLines: SyndicateLine[] = originalContractResponse.sections.flatMap(
    section => section.lines.filter(line => line.nominatedSyndicateId === 1618),
  )

  const references = [
    ...new Set(
      allKiLines.flatMap(
        line => line.syndicateData?.syndicateData?.britProgrammeReference || [],
      ),
    ),
  ]

  if (references.length === 0) {
    return []
  }

  return references.map(reference => {
    const filteredSections: ContractSectionItem[] =
      originalContractResponse.sections
        .map(section => {
          const newLines = section.lines.filter(
            line =>
              line.nominatedSyndicateId === 1618 &&
              line.syndicateData?.syndicateData?.britProgrammeReference ===
                reference,
          )
          return { ...section, lines: newLines }
        })
        .filter(section => section.lines.length > 0)

    return {
      ...originalContractResponse,
      sections: filteredSections,
    }
  })
}
