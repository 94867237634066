import { FC, useState } from "react"

import { Button } from "@appia/ui-components"
import {
  Endorsement,
  EndorsementStatus,
  EndorsementType,
  Policy,
  updateEndorsementPolicy,
} from "@appia/api"
import * as RD from "@appia/remote-data"
import * as Sentry from "@sentry/react"

import useApiClient from "src/contexts/ApiClientContext"
import usePageName from "src/contexts/PageNameContext"

import { logButtonClick } from "src/amplitude"

const ProcessCTA: FC<{
  endorsementId: Endorsement["id"]
  endorsementStatus: EndorsementStatus
  endorsementType: EndorsementType
  onFailure: () => void
  onSuccess: () => void
  policyId: Policy["policyId"] | null
}> = ({
  endorsementId,
  endorsementStatus,
  endorsementType,
  onFailure,
  onSuccess,
  policyId,
}) => {
  const apiClient = useApiClient()
  const pageName = usePageName()

  const [updateReq, setUpdateReq] = useState<RD.RemoteData<Error, null>>(
    RD.NotAsked,
  )

  const buttonLabel =
    endorsementStatus === "accepted_by_decider" || endorsementType === "notice"
      ? "Complete"
      : "Process"

  return (
    <Button
      label={buttonLabel}
      disabled={!policyId || endorsementStatus === "review_complete"}
      onClick={async () => {
        if (!policyId) {
          return
        }

        logButtonClick({
          pageName,
          buttonName: buttonLabel,
          containerName: "Header",
        })

        setUpdateReq(RD.Loading)

        try {
          updateEndorsementPolicy(apiClient, endorsementId, { policyId })

          setUpdateReq(RD.Success(null))
          onSuccess()
        } catch (e) {
          if (e instanceof Error) {
            Sentry.captureException(e)
            setUpdateReq(RD.Failure(e))
            onFailure()
          }
        }
      }}
      className="col-start-2"
      style="filled"
      theme="pop"
      size="small"
      stretch="space-around"
      isLoading={RD.isLoading(updateReq)}
    />
  )
}

export default ProcessCTA
