import { FC } from "react"

import {
  Button,
  Callout,
  DownloadIcon,
  Link,
  ModalDescription,
} from "@appia/ui-components"

import ModalTemplate from "src/components/ModalTemplate"
import PolicyReferencesTable from "src/components/PolicyReferencesTable"

import { Document, Endorsement, EndorsementType } from "@appia/api"
import * as RD from "@appia/remote-data"
import * as Sentry from "@sentry/react"

import usePageName from "src/contexts/PageNameContext"
import { logButtonClick } from "src/amplitude"
import { downloadFile } from "src/utils/download"
import { useGetEndorsementDocuments } from "src/swr"
import ErrorMessage from "src/components/ErrorMessage"
import setEndorsementFileName from "src/utils/setEndorsementFileName"

const CONTAINER_NAME = "Completion modal"

interface EndorsementTypeText {
  key: EndorsementType
  title: string
  text: string[]
}

const endorsementType: EndorsementTypeText[] = [
  {
    key: "request",
    title: "Request",
    text: [
      "Download the stamped endorsement from the placing platform and save it in the DMS.",
      "If partners are on the risk, you need to send the stamped endorsement to partners.",
      "If updates are needed in Eclipse, create a TPU item.",
    ],
  },
  {
    key: "notice",
    title: "Notification",
    text: ["You now need to download the endorsement and save it in the DMS."],
  },
]

const downloadDocument = ({ url, name, mimetype }: Document): Promise<void> =>
  downloadFile(url, name, mimetype)

const excludeEmails = (docs: Document[]): Document[] =>
  docs.filter(doc => !doc.name.toLowerCase().includes("email"))

const CompletionModal: FC<{
  completionReq: RD.RemoteData<Error, Endorsement>
  isOpen: boolean
  onClose: () => void
}> = ({ completionReq, isOpen, onClose }) => {
  const pageName = usePageName()

  const endorsement = RD.isSuccess(completionReq) ? completionReq.data : null
  const { request: documentsRequest } = useGetEndorsementDocuments(
    endorsement?.id,
  )
  const documents = RD.isSuccess(documentsRequest) ? documentsRequest.data : []

  const handleDownload = (): void => {
    const filteredDocs = excludeEmails(documents)
    if (filteredDocs.length < 1) {
      const error = new Error(
        "No documents to download in Review Endorsement Completion",
      )
      Sentry.captureException(error)
      return
    }

    filteredDocs.forEach(doc => {
      const downloadedFileName = setEndorsementFileName(
        doc.name,
        endorsement?.umr,
        endorsement?.number,
      )

      downloadDocument({ ...doc, name: downloadedFileName })
    })
  }

  const type = endorsementType.find(type => type.key === endorsement?.type)

  return endorsement?.id ? (
    <div data-testid="endorsement-completion-modal">
      <ModalTemplate
        className="lg:w-full lg:max-w-[min(50rem,calc(100%-4rem))] "
        isOpen={isOpen}
        onClose={onClose}
        title={`${type?.title} endorsement has been completed`}
        content={
          <ModalDescription as="div">
            <Callout type="information">
              <div className="font-bold">Next steps</div>
              <div className="pr-4">
                {type?.text.map((step, index) => (
                  <li key={index}>{step}</li>
                ))}
              </div>
            </Callout>

            {type?.key === "notice" && (
              <>
                <div className="p-1 pt-4">
                  Note that the endorsement will automatically be sent to
                  partners.
                </div>
                <div className="flex justify-end p-3 pt-0 pt-4">
                  <Button
                    label="Download endorsement"
                    style="outlined"
                    theme="night"
                    onClick={handleDownload}
                    icon={{
                      position: "right",
                      icon: <DownloadIcon />,
                    }}
                  />
                </div>
              </>
            )}

            {endorsement.policyReferences.length > 0 && (
              <div className="mt-4 overflow-auto">
                <PolicyReferencesTable
                  policyReferences={endorsement.policyReferences}
                />
              </div>
            )}
          </ModalDescription>
        }
        actions={[
          <Link
            key="summary"
            href={`/endorsements/view/${endorsement.id}`}
            label="Go to summary"
            style="outlined"
            theme="night"
            onClick={() => {
              logButtonClick({
                buttonName: "Go to summary",
                linkHref: `/endorsements/view/${endorsement.id}`,
                containerName: CONTAINER_NAME,
                pageName,
              })
            }}
          />,
          <Link
            key="dashboard"
            label="Go to dashboard"
            href="/endorsements"
            theme="pop"
            style="filled"
            onClick={() => {
              logButtonClick({
                buttonName: "Go to dashboard",
                linkHref: `/endorsements`,
                containerName: CONTAINER_NAME,
                pageName,
              })
            }}
          />,
        ]}
      />
    </div>
  ) : (
    <ModalTemplate
      isOpen={isOpen}
      onClose={onClose}
      title="Not confirmed"
      content={
        <ModalDescription as="div">
          {RD.isFailure(completionReq) && (
            <ErrorMessage
              message="Sorry, there was an error while saving your changes. Please try again."
              error={completionReq.error}
            />
          )}
        </ModalDescription>
      }
      actions={[
        <Button
          key="return"
          label="Return to endorsement"
          style="outlined"
          theme="night"
          onClick={() => {
            onClose()
            logButtonClick({
              buttonName: "Return to endorsement",
              containerName: CONTAINER_NAME,
              pageName,
            })
          }}
        />,
      ]}
    />
  )
}

export default CompletionModal
