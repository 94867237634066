import * as Outline from "@heroicons/react/outline"

import AddNoteSVG from "./AddNoteSVG"
import ArchiveSVG from "./ArchiveSVG"
import ArrowLeftSVG from "./ArrowLeftSVG"
import ArrowRightAltSVG from "./ArrowRightAltSVG"
import ArrowRightSVG from "./ArrowRightSVG"
import BanIconSVG from "./BanIconSVG"
import BoltSVG from "./BoltSVG"
import CalculateSVG from "./CalculateSVG"
import ChatAltSVG from "./ChatAltSVG"
import CheckCircleSVG from "./CheckCircleSVG"
import CheckFilledCircleSVG from "./CheckFilledCircleSVG"
import CheckSVG from "./CheckSVG"
import ChevronDownSVG from "./ChevronDownSVG"
import ChevronLeftSVG from "./ChevronLeftSVG"
import ChevronRightSVG from "./ChevronRightSVG"
import ChevronUpSVG from "./ChevronUpSVG"
import ClipboardListSVG from "./ClipboardListSVG"
import ControlPointDuplicateSVG from "./ControlPointDuplicateSVG"
import CopyRightSVG from "./CopyRightSVG"
import DotsVerticalSVG from "./DotsVerticalSVG"
import DownloadSVG from "./DownloadSVG"
import DuplicateSVG from "./DuplicateSVG"
import EditNoteSVG from "./EditNoteSVG"
import EmptySVG from "./EmptySVG"
import ExclamationCircleSVG from "./ExclamationCircleSVG"
import ExclamationFilledCircleSVG from "./ExclamationFilledCircleSVG"
import ExclamationSVG from "./ExclamationSVG"
import FactCheckSVG from "./FactCheckSVG"
import FileDocsSVG from "./FileDocsSVG"
import FilterSVG from "./FilterSVG"
import FirstPageSVG from "./FirstPageSVG"
import GlobeSVG from "./GlobeSVG"
import HourglassEmptySVG from "./HourglassEmptySVG"
import InformationCircleSVG from "./InformationCircleSVG"
import InformationFilledCircleSVG from "./InformationFilledCircleSVG"
import InsertLinkSVG from "./InsertLinkSVG"
import LastPageSVG from "./LastPageSVG"
import MenuSVG from "./MenuSVG"
import MinusSVG from "./MinusSVG"
import PendingSVG from "./PendingSVG"
import PlusSVG from "./PlusSVG"
import PrinterSVG from "./PrinterSVG"
import QuestionPageSVG from "./QuestionPageSVG"
import RestoreSVG from "./RestoreSVG"
import RotateRightSVG from "./RotateRightSVG"
import SelectorSVG from "./SelectorSVG"
import SendSVG from "./SendSVG"
import SortAscendingSVG from "./SortAscendingSVG"
import SortDescendingSVG from "./SortDescendingSVG"
import SpinnerSVG from "./SpinnerSVG"
import StarCircleSVG from "./StarCircleSVG"
import TargetSVG from "./TargetSVG"
import TrashSVG from "./TrashSVG"
import UploadSVG from "./UploadSVG"
import UserAcceptedSVG from "./UserAcceptedSVG"
import VisibilityOffSVG from "./VisibilityOffSVG"
import VisibilitySVG from "./VisibilitySVG"
import XSVG from "./XSVG"

import type { Icon } from "./IconWrapper"
import { IconWrapper } from "./IconWrapper"
import ExclamationPageSVG from "./ExclamationPageSVG"

export type { Icon } from "./IconWrapper"

export const AddNoteIcon: Icon = props => (
  <IconWrapper icon={AddNoteSVG} {...props} />
)

export const ArchiveIcon: Icon = props => (
  <IconWrapper icon={ArchiveSVG} {...props} />
)

export const ArrowLeftIcon: Icon = props => (
  <IconWrapper icon={ArrowLeftSVG} {...props} />
)

export const ArrowRightAltIcon: Icon = props => (
  <IconWrapper icon={ArrowRightAltSVG} {...props} />
)

export const ArrowRightIcon: Icon = props => (
  <IconWrapper icon={ArrowRightSVG} {...props} />
)

export const BanIcon: Icon = props => (
  <IconWrapper icon={BanIconSVG} {...props} />
)

export const BoltIcon: Icon = props => <IconWrapper icon={BoltSVG} {...props} />

export const CalculateIcon: Icon = props => (
  <IconWrapper icon={CalculateSVG} {...props} />
)

export const ChatAltIcon: Icon = props => (
  <IconWrapper icon={ChatAltSVG} {...props} />
)

export const CheckIcon: Icon = props => (
  <IconWrapper icon={CheckSVG} {...props} />
)

export const CheckCircleIcon: Icon = props => (
  <IconWrapper icon={CheckCircleSVG} {...props} />
)

export const CheckFilledCircleIcon: Icon = props => (
  <IconWrapper icon={CheckFilledCircleSVG} {...props} />
)

export const ChevronDownIcon: Icon = props => (
  <IconWrapper icon={ChevronDownSVG} {...props} />
)

export const ChevronLeftIcon: Icon = props => (
  <IconWrapper icon={ChevronLeftSVG} {...props} />
)

export const ChevronRightIcon: Icon = props => (
  <IconWrapper icon={ChevronRightSVG} {...props} />
)

export const ChevronUpIcon: Icon = props => (
  <IconWrapper icon={ChevronUpSVG} {...props} />
)

export const ClipboardListIcon: Icon = props => (
  <IconWrapper icon={ClipboardListSVG} {...props} />
)

export const ControlPointDuplicateIcon: Icon = props => (
  <IconWrapper icon={ControlPointDuplicateSVG} {...props} />
)

export const CopyRightIcon: Icon = props => (
  <IconWrapper icon={CopyRightSVG} {...props} />
)

export const DocumentSearchIcon: Icon = props => (
  <IconWrapper icon={Outline.DocumentSearchIcon} {...props} />
)

export const DotsVerticalIcon: Icon = props => (
  <IconWrapper icon={DotsVerticalSVG} {...props} />
)

export const DownloadIcon: Icon = props => (
  <IconWrapper icon={DownloadSVG} {...props} />
)

export const DuplicateIcon: Icon = props => (
  <IconWrapper icon={DuplicateSVG} {...props} />
)

export const EditNoteIcon: Icon = props => (
  <IconWrapper icon={EditNoteSVG} {...props} />
)

export const EmptyIcon: Icon = props => (
  <IconWrapper icon={EmptySVG} {...props} />
)

export const ExclamationIcon: Icon = props => (
  <IconWrapper icon={ExclamationSVG} {...props} />
)

export const ExclamationCircleIcon: Icon = props => (
  <IconWrapper icon={ExclamationCircleSVG} {...props} />
)

export const ExclamationFilledCircleIcon: Icon = props => (
  <IconWrapper icon={ExclamationFilledCircleSVG} {...props} />
)

export const ExclamationPageIcon: Icon = props => (
  <IconWrapper icon={ExclamationPageSVG} {...props} />
)

export const FactCheckIcon: Icon = props => (
  <IconWrapper icon={FactCheckSVG} {...props} />
)

export const FileDocsIcon: Icon = props => (
  <IconWrapper icon={FileDocsSVG} {...props} />
)

export const FilterIcon: Icon = props => (
  <IconWrapper icon={FilterSVG} {...props} />
)

export const FirstPageIcon: Icon = props => (
  <IconWrapper icon={FirstPageSVG} {...props} />
)

export const GlobeIcon: Icon = props => (
  <IconWrapper icon={GlobeSVG} {...props} />
)

export const HourglassEmptyIcon: Icon = props => (
  <IconWrapper icon={HourglassEmptySVG} {...props} />
)

export const InformationCircleIcon: Icon = props => (
  <IconWrapper icon={InformationCircleSVG} {...props} />
)

export const InformationFilledCircleIcon: Icon = props => (
  <IconWrapper icon={InformationFilledCircleSVG} {...props} />
)

export const InsertLinkIcon: Icon = props => (
  <IconWrapper icon={InsertLinkSVG} {...props} />
)

export const LastPageIcon: Icon = props => (
  <IconWrapper icon={LastPageSVG} {...props} />
)

export const MenuIcon: Icon = props => <IconWrapper icon={MenuSVG} {...props} />

export const MinusIcon: Icon = props => (
  <IconWrapper icon={MinusSVG} {...props} />
)

export const PendingIcon: Icon = props => (
  <IconWrapper icon={PendingSVG} {...props} />
)

export const PlusIcon: Icon = props => <IconWrapper icon={PlusSVG} {...props} />

export const PrinterIcon: Icon = props => (
  <IconWrapper icon={PrinterSVG} {...props} />
)

export const QuestionPageIcon: Icon = props => (
  <IconWrapper icon={QuestionPageSVG} {...props} />
)

export const RestoreIcon: Icon = props => (
  <IconWrapper icon={RestoreSVG} {...props} />
)

export const RotateRightIcon: Icon = props => (
  <IconWrapper icon={RotateRightSVG} {...props} />
)

export const SearchIcon: Icon = props => (
  <IconWrapper icon={Outline.SearchIcon} {...props} />
)

export const SortAscendingIcon: Icon = props => (
  <IconWrapper icon={SortAscendingSVG} {...props} />
)

export const SortDescendingIcon: Icon = props => (
  <IconWrapper icon={SortDescendingSVG} {...props} />
)

export const SelectorIcon: Icon = props => (
  <IconWrapper icon={SelectorSVG} {...props} />
)

export const SendIcon: Icon = props => <IconWrapper icon={SendSVG} {...props} />

export const SpinnerIcon: Icon = props => (
  <IconWrapper icon={SpinnerSVG} {...props} />
)

export const StarCircleIcon: Icon = props => (
  <IconWrapper icon={StarCircleSVG} {...props} />
)

export const TargetIcon: Icon = props => (
  <IconWrapper icon={TargetSVG} {...props} />
)

export const TrashIcon: Icon = props => (
  <IconWrapper icon={TrashSVG} {...props} />
)

export const UploadIcon: Icon = props => (
  <IconWrapper icon={UploadSVG} {...props} />
)

export const UserAcceptedIcon: Icon = props => (
  <IconWrapper icon={UserAcceptedSVG} {...props} />
)

export const UserIcon: Icon = props => (
  <IconWrapper icon={Outline.UserIcon} {...props} />
)

export const VisibilityIcon: Icon = props => (
  <IconWrapper icon={VisibilitySVG} {...props} />
)

export const VisibilityOffIcon: Icon = props => (
  <IconWrapper icon={VisibilityOffSVG} {...props} />
)

export const XIcon: Icon = props => <IconWrapper icon={XSVG} {...props} />

export const ZoomInIcon: Icon = props => (
  <IconWrapper icon={Outline.ZoomInIcon} {...props} />
)

export const ZoomOutIcon: Icon = props => (
  <IconWrapper icon={Outline.ZoomOutIcon} {...props} />
)
