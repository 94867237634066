import React, { useId, useState } from "react"
import { DragDrop } from "@uppy/react"
import { SuccessResponse } from "@uppy/core"
import { assignEndorsement, generateEndorsementUploadUrl } from "@appia/api"
import useApiClient from "src/contexts/ApiClientContext"
import * as RD from "@appia/remote-data"
import * as Sentry from "@sentry/react"
import {
  logEndorsementUploadComplete,
  logEndorsementUploadStart,
} from "src/amplitude"
import { FileUploadArea } from "@appia/ui-components"
import useUppy, { StorageProvider } from "@appia/file-upload"
import ErrorMessage from "src/components/ErrorMessage"
import SuccessModal from "./SuccessModal"
import { useGetCurrentUser } from "../../../swr"
import ProgressBar from "./ProgressBar"
import { FormField } from "@appia/form-data"

const FILETYPE_MSG = "Otto only accepts .pdf Endorsement files."
const ACCEPTED_FILE_TYPES = [".pdf"]
const PROGRESS_ID = "upload-progress"

export interface UploadAreaProps {
  stateAnswers: {
    umr: FormField<string>
    insuredName: FormField<string>
    endorsementType: FormField<string>
    source: FormField<string>
    endorsementNumber: FormField<string>
  }
}

const UploadArea: React.FC<UploadAreaProps> = ({ stateAnswers }) => {
  const apiClient = useApiClient()
  const { request: userRequest } = useGetCurrentUser()
  const user = RD.isSuccess(userRequest) ? userRequest.data.user : undefined

  const [uploadRequest, setUploadRequest] = useState<
    RD.RemoteData<Error, SuccessResponse>
  >(RD.NotAsked)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false)
  const [endorsementId, setEndorsementId] = useState<string | null>(null)

  const uppy = useUppy({
    maxMegabytes: 50,
    acceptedDocumentTypes: ACCEPTED_FILE_TYPES,
    provider: StorageProvider.AWS_S3,
    getUploadParameters: async file => {
      if (!file.type) {
        Sentry.captureException(new Error("File type unrecognized"))
        throw new Error("File type unrecognized")
      }

      const preUploadRequest = {
        name: file.name,
        mimetype: "application/pdf",
        extension: "pdf",
        companyId: user!.companyId,
        teamId: user!.teamId!,
        externalId: "",
        endorsementNumber: stateAnswers.endorsementNumber.raw,
        size: file.size,
        url: "",
        umr: stateAnswers.umr.raw,
        insuredName: stateAnswers.insuredName.raw,
        endtType: stateAnswers.endorsementType.raw,
        source: stateAnswers.source.raw,
      }

      logEndorsementUploadStart({
        fileId: file.id,
        umr: preUploadRequest.umr,
        mimetype: preUploadRequest.mimetype,
        externalId: preUploadRequest.externalId,
      })

      const urlRes = await generateEndorsementUploadUrl(
        apiClient,
        preUploadRequest,
      )

      if (urlRes.status !== 201) {
        Sentry.captureException(
          new Error(
            `Server upload error: ${urlRes.status} ${urlRes.statusText}`,
          ),
        )
        throw new Error(
          `Server upload error: ${urlRes.status} ${urlRes.statusText}`,
        )
      }

      const { url, endorsement_id: endorsementId, fields } = urlRes.data
      setEndorsementId(endorsementId)

      if (!url) {
        Sentry.captureException(new Error("Missing upload URL"))
        throw new Error("Missing upload URL")
      }

      uppy.setFileMeta(file.id, {
        fileId: file.id,
        umr: preUploadRequest.umr,
        fileName: file.name,
      })

      return {
        url,
        fields,
        // Below is the future GCS setup
        // ..
        // method: "PUT",
        // headers: {
        //   "Content-Type": file.type || "application/octet-stream",
        // },
      }
    },
    onStartLoad: () => setUploadRequest(RD.Loading),
    onProgress: setUploadProgress,
    onError: async ({ error }) => {
      Sentry.captureException(error)
      setUploadRequest(RD.Failure(error))
    },
    onSuccess: async (res, file) => {
      if (file) {
        logEndorsementUploadComplete({ fileId: file.id })
        setUploadRequest(RD.Success(res))
        setIsSuccessModalOpen(true)
      }
    },
  })

  if (user && endorsementId) {
    assignEndorsement(apiClient, endorsementId, user.id)
  }

  const headingId = useId()

  return (
    <div className="relative">
      <div role="alert" className="mx-auto w-max max-w-full bg-white">
        {RD.match(
          uploadRequest,
          null,
          <div className="absolute top-0 left-0 z-[1] flex h-full w-full items-center justify-center bg-otto-grey-100 bg-opacity-75 transition-opacity">
            <ProgressBar id={PROGRESS_ID} progress={uploadProgress} />
          </div>,
          () => (
            <SuccessModal
              isOpen={isSuccessModalOpen}
              endorsementId={endorsementId}
              onClose={() => {
                setIsSuccessModalOpen(false)
                setUploadRequest(RD.NotAsked)
                setUploadProgress(0)
              }}
            />
          ),
          error => (
            <div className="mb-8">
              <ErrorMessage message={`Upload failed: ${error.message}`} />
            </div>
          ),
        )}
      </div>

      <p className="mb-4 text-center text-2xl font-bold">
        Please upload one endorsement file at a time.
      </p>

      <section aria-labelledby={headingId} className="mx-auto max-w-5xl">
        <h2 className="sr-only" id={headingId}>
          Upload Endorsement
        </h2>

        <FileUploadArea
          label={
            <div className="grid gap-2">
              <p className="text-xl font-bold">Drop your file here</p>
              <p className="text-base">Or browse files from your computer</p>
              <p className="text-base">{FILETYPE_MSG}</p>
            </div>
          }
          input={
            <DragDrop
              uppy={uppy}
              className="absolute top-0 bottom-0 left-0 right-0 cursor-pointer opacity-0"
              aria-describedby={
                RD.isLoading(uploadRequest) ? PROGRESS_ID : undefined
              }
            />
          }
        />
      </section>
    </div>
  )
}

export default UploadArea
