import { FC } from "react"
import { Button, ModalDescription } from "@appia/ui-components"

import PolicyReferencesTable from "src/components/PolicyReferencesTable"
import ModalTemplate from "src/components/ModalTemplate"
import { Endorsement } from "@appia/api"

const PolicyReferencesModal: FC<{
  isOpen: boolean
  onClose: () => void
  policyReferences: Endorsement["policyReferences"]
}> = ({ isOpen, onClose, policyReferences }) => {
  return (
    <ModalTemplate
      className="lg:w-full lg:max-w-[min(50rem,calc(50%-4rem))]"
      isOpen={isOpen}
      onClose={onClose}
      title="Policy references"
      content={
        <ModalDescription as="div" className="px-2">
          <div className="mt-2 overflow-auto">
            <PolicyReferencesTable
              policyReferences={policyReferences}
              showWrittenLineSize={false}
            />
          </div>
        </ModalDescription>
      }
      actions={[
        <Button
          key="close"
          style="outlined"
          label="Close"
          theme="night"
          onClick={onClose}
        />,
      ]}
    />
  )
}

export default PolicyReferencesModal
