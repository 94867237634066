import type { SVGComponent } from "./IconWrapper"

const FileDocsSVG: SVGComponent = props => (
  <svg viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M4.20832 18.6458L3.04166 17.4583L5.49999 15H3.62499V13.3333H8.33332V18.0417H6.66666V16.1875L4.20832 18.6458ZM9.99999 18.3333V16.6667H15V7.5H10.8333V3.33334H4.99999V11.6667H3.33332V3.33334C3.33332 2.87501 3.49652 2.48264 3.82291 2.15626C4.1493 1.82987 4.54166 1.66667 4.99999 1.66667H11.6667L16.6667 6.66667V16.6667C16.6667 17.125 16.5035 17.5174 16.1771 17.8438C15.8507 18.1701 15.4583 18.3333 15 18.3333H9.99999Z"
      fill="#FEF8D7"
    />
  </svg>
)

export default FileDocsSVG
