import { FC, useId, useState } from "react"
import { Navigate, useParams } from "react-router-dom"

import { Document, EndorsementStatus } from "@appia/api"
import * as RD from "@appia/remote-data"
import {
  useGetEndorsement,
  useGetEndorsementDocuments,
  useGetUsers,
} from "src/swr"

import { logButtonClick, logFileDownload, useLogPageView } from "src/amplitude"

import {
  Card,
  ChevronRightIcon,
  DownloadIcon,
  IconButton,
  Link,
} from "@appia/ui-components"

import DefinitionList, { DefinitionItem } from "src/components/DefinitionList"
import ErrorMessage from "src/components/ErrorMessage"
import Loading from "src/components/Loading"
import PolicyReferencesTable from "src/components/PolicyReferencesTable"
import ScreenTemplate from "src/templates/ScreenTemplate"
import { Row, Table } from "./Table"

import useDocumentTitle from "src/hooks/useDocumentTitle"
import { PageNameContext } from "src/contexts/PageNameContext"

import { accountIdToFullnameOrEmail } from "src/utils/users"
import { downloadFile } from "src/utils/download"
import { prettyPrintDateString } from "src/utils/prettyPrinters"
import setEndorsementFileName from "src/utils/setEndorsementFileName"
import DownloadWarningModal from "src/components/DownloadWarningModal"

const PAGE_NAME = "Endorsement details"

type ViewEndorsementStatus = Extract<
  EndorsementStatus,
  "referred_to_decider" | "rejected_by_decider" | "review_complete"
>

const listOfAcceptableStatuses: ViewEndorsementStatus[] = [
  "referred_to_decider",
  "rejected_by_decider",
  "review_complete",
]

const ViewScreen: FC = () => {
  const { id: endorsementId } = useParams<{ id: string }>()

  if (endorsementId === undefined) {
    throw new Error("Missing endorsementId in ViewEndorsement screen")
  }

  useLogPageView({ pageName: PAGE_NAME })
  useDocumentTitle(PAGE_NAME)

  const { request: endorsementRequest } = useGetEndorsement(endorsementId)
  const { request: documentsRequest } =
    useGetEndorsementDocuments(endorsementId)
  const documents = RD.isSuccess(documentsRequest) ? documentsRequest.data : []

  const endorsementData = RD.isSuccess(endorsementRequest)
    ? endorsementRequest.data
    : undefined

  const umr = endorsementData?.umr
  const endorsementNumber = endorsementData?.number
  const insured = endorsementData?.policyData?.insured
  const status = endorsementData?.status
  const teamId = endorsementData?.teamId
  const type = endorsementData?.type

  const { request: usersRequest } = useGetUsers(teamId || null)

  const users = RD.isSuccess(usersRequest) ? usersRequest.data.users : []

  useDocumentTitle((insured ? `${insured} | ` : "") + "Endorsement details")

  const referencesLabelId = useId()

  const isWrongStatus =
    status && !listOfAcceptableStatuses.some(s => s === status)

  const [isWarningModalOpen, setWarningModalOpen] = useState(false)
  const [documentToDownload, setDocumentToDownload] = useState<Document>()
  const [downloadedFileName, setDownloadedFileName] = useState<string>()

  const isShowEndorsementTypeWarning = type === "request"

  const downloadDocument = (doc: Document, fileName: string): void => {
    downloadFile(doc.url, fileName, doc.mimetype)
    logFileDownload({ fileName })

    logButtonClick({
      buttonName: "Download",
      containerName: "Main",
      pageName: PAGE_NAME,
    })
  }

  const showWarningModal = (
    document: Document,
    downloadedFileName: string,
  ): void => {
    setDocumentToDownload(document)
    setDownloadedFileName(downloadedFileName)
    setWarningModalOpen(true)
  }

  const handleDownloadClick =
    (document: Document, downloadedFileName: string) => (): void => {
      if (isShowEndorsementTypeWarning) {
        showWarningModal(document, downloadedFileName)
      } else {
        downloadDocument(document, downloadedFileName)
      }
    }

  const confirmDownload = (): void => {
    if (!documentToDownload || !downloadedFileName) return

    downloadDocument(documentToDownload, downloadedFileName)
    setWarningModalOpen(false)
  }

  if (isWrongStatus) {
    return <Navigate to="/404" />
  }

  return (
    <PageNameContext.Provider value={PAGE_NAME}>
      <ScreenTemplate
        pageTitle={PAGE_NAME}
        layout={{ type: "regular", backPath: "/endorsements" }}
      >
        {RD.match(
          endorsementRequest,
          <Loading />,
          <Loading />,
          ({
            broker,
            completedAt,
            completedBy,
            policyData,
            policyReferences,
            referrals,
            rejectedBy,
            rejectionReason,
            status,
            type,
          }) => {
            const commonItems: DefinitionItem[] = [
              {
                label: "Assured name",
                value: policyData?.insured,
              },
              {
                label: "Broker",
                value: broker?.name,
              },
              {
                label: "Type",
                value: type === "request" ? "Request" : "Notification",
              },
              {
                label: "Eclipse reference",
                value: policyData?.policyReference,
              },
            ]

            const rejectedByItem: DefinitionItem[] = [
              {
                label: "Rejected by",
                value: rejectedBy,
              },
            ]

            const rejectedReasonItem: DefinitionItem[] = [
              {
                label: "Rejected",
                value: rejectionReason,
              },
            ]

            const reviewCompleteItems: DefinitionItem[] = [
              ...commonItems,
              {
                label: "Completed on",
                value: completedAt && prettyPrintDateString(completedAt),
              },
              {
                label: "Completed by",
                value: accountIdToFullnameOrEmail(completedBy, users),
              },
            ]

            const referredToPuTableHeaders: string[] = [
              "Referred on",
              "Referred by",
              "Referred to",
            ]

            const referredToPuTableRows: Row[] = referrals.flatMap(referral =>
              referral.emails.map(email => ({
                cells: [
                  prettyPrintDateString(referral.referredAt),
                  accountIdToFullnameOrEmail(referral.referredBy, users) ?? "",
                  email.referralName,
                ],
              })),
            )

            const rejectedByPuTableHeaders: string[] = [
              "Referred on",
              "Referred by",
            ]

            const rejectedByPuTableRows: Row[] = referrals.map(referral => ({
              cells: [
                prettyPrintDateString(referral.referredAt),
                accountIdToFullnameOrEmail(referral.referredBy, users) ?? "",
              ],
            }))

            const toComponents: Record<ViewEndorsementStatus, JSX.Element> = {
              referred_to_decider: (
                <>
                  <DefinitionList
                    className="grid grid-cols-[repeat(2,1fr)]"
                    items={commonItems}
                  />
                  <Table
                    caption="Endorsements referred to PU"
                    rows={referredToPuTableRows}
                    headers={referredToPuTableHeaders}
                  />
                </>
              ),
              rejected_by_decider: (
                <div className="grid-cols-1fr grid items-start gap-8 sm:grid-cols-[repeat(2,1fr)] md:grid-cols-[repeat(3,1fr)]">
                  <DefinitionList
                    className="grid grid-cols-[repeat(2,1fr)]"
                    items={commonItems}
                  />
                  <Table
                    caption="Endorsements rejected by decider"
                    rows={rejectedByPuTableRows}
                    headers={rejectedByPuTableHeaders}
                  />
                  <DefinitionList items={rejectedByItem} />
                  <DefinitionList items={rejectedReasonItem} />
                </div>
              ),
              review_complete: <DefinitionList items={reviewCompleteItems} />,
            }

            return (
              <div className="grid gap-4">
                <Card className="flex flex-wrap items-start justify-between gap-8 md:flex-nowrap">
                  {toComponents[status as ViewEndorsementStatus]}

                  <Link
                    theme="pop"
                    style="filled"
                    label="Review"
                    icon={{ position: "right", icon: <ChevronRightIcon /> }}
                    href={`/endorsements/review/${endorsementId}`}
                    onClick={() => {
                      logButtonClick({
                        buttonName: "Review",
                        containerName: "Main",
                        pageName: PAGE_NAME,
                        linkHref: `/endorsements/review/${endorsementId}`,
                      })
                    }}
                  />
                </Card>

                <section aria-labelledby="documents-label">
                  <h3 id="documents-label" className="mb-2 text-xl">
                    Documents
                  </h3>

                  {documents.map(doc => {
                    const downloadedFileName = setEndorsementFileName(
                      doc.name,
                      umr,
                      endorsementNumber,
                    )

                    return (
                      <Card
                        key={doc.id}
                        className="mb-4 flex w-full flex-wrap items-start justify-between gap-8 sm:flex-nowrap"
                      >
                        <DefinitionList
                          items={[
                            { label: "Name", value: doc.name },
                            {
                              label: "Uploaded on",
                              value: prettyPrintDateString(doc.createdAt),
                            },
                          ]}
                        />

                        <IconButton
                          data-testid={`download-btn-${doc.id}`}
                          icon={<DownloadIcon />}
                          size={6}
                          label={`Download document ${doc.name}`}
                          onClick={handleDownloadClick(doc, downloadedFileName)}
                        />
                      </Card>
                    )
                  })}
                </section>

                <section aria-labelledby={referencesLabelId}>
                  <h3 id={referencesLabelId} className="mb-2 text-xl">
                    References
                  </h3>

                  <section className="mb-4 flex w-full items-start justify-between gap-8 overflow-auto">
                    <PolicyReferencesTable
                      policyReferences={policyReferences}
                    />
                  </section>
                </section>

                <DownloadWarningModal
                  isOpen={isWarningModalOpen}
                  onClose={() => setWarningModalOpen(false)}
                  onConfirm={confirmDownload}
                  title="This is not the stamped endorsement"
                  message="Note that this file is not the stamped endorsement, and so should not be sent to partners."
                />
              </div>
            )
          },

          error => {
            const status = error.response?.status
            return status === 404 ? (
              <Navigate replace to="/404" />
            ) : (
              <Card>
                <ErrorMessage
                  message="Failed to load the endorsement details:"
                  error={error}
                />
              </Card>
            )
          },
        )}
      </ScreenTemplate>
    </PageNameContext.Provider>
  )
}

export default ViewScreen
