import { FC, ReactNode } from "react"

import { EndorsementsSearchResult } from "@appia/api"

import {
  ArchiveIcon,
  DotsVerticalIcon,
  IconMenu,
  MenuItem,
} from "@appia/ui-components"

const Menu: FC<{ children: ReactNode }> = ({ children }) => (
  <IconMenu
    buttonTextAccessible="Action menu"
    icon={() => <DotsVerticalIcon />}
  >
    {children}
  </IconMenu>
)

const ActionMenu: FC<{
  endorsement: EndorsementsSearchResult
  onArchive: () => void
}> = ({ endorsement, onArchive }) => {
  switch (endorsement.status) {
    case "archived":
    case "created":
    case "processing":
      return null

    case "failed":
    case "blocked_awaiting_third_party":
    case "referred_to_decider":
    case "accepted_by_decider":
    case "rejected_by_decider":
    case "review_required":
    case "review_in_progress":
    case "review_complete":
    case "file_docs":
      return (
        <Menu>
          <MenuItem
            onClick={onArchive}
            className="flex items-center justify-start gap-2"
          >
            <ArchiveIcon className="w-5 text-otto-grey-700" />
            <span>Archive</span>
          </MenuItem>
        </Menu>
      )
  }
}

export default ActionMenu
