import { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import * as Sentry from "@sentry/react"
import axios from "axios"

import {
  ContractResponse,
  completeContract,
  updateContractSections,
} from "@appia/api"
import * as RD from "@appia/remote-data"
import { Toast } from "@appia/ui-components"

import useApiClient from "src/contexts/ApiClientContext"
import {
  useGetContract,
  useGetCurrentUser,
  useGetKiQuote,
  useGetPBQADocuments,
} from "src/swr"
import { useToastHandler } from "src/hooks/useToastHandler"

import HeaderSection from "./HeaderSection"
import SectionFooter from "./SectionFooter"
import LayerDisclosure from "./LayerDisclosure"
import ErrorMessage from "src/components/ErrorMessage"
import Loading from "src/components/Loading"
import ToastViewport from "src/components/ToastViewport"
import { DocumentContent } from "src/screens/ReviewEndorsement/Documents/TabLayout"
import ContractSectionLayer from "./ContractSectionLayer"
import ContractSectionEnergyLayer from "./ContractSectionEnergyLayer"
import RarcDefaultWarningBanner from "../RarcDefaultWarningBanner"

import { REVIEW_CONTRACT_GENERAL_PATH } from ".."
import { calculateTotalAllocatedPremium } from "./utils/premiumUtils"
import { LayerFormField } from "./utils/types"
import {
  hasStampedSlipBeenUploaded,
  isContractHeaderValid,
  isLayerValid,
  validateSectionFormField,
} from "./utils/validationUtils"
import {
  initializeLayerFormFields,
  isKi1618EnergyLayer,
  updateContractDataWithFormFields,
} from "./utils/formUtils"
import { processLayer, updateLayerErrors } from "./utils/sectionFormUtils"
import { getKiProgrammeRef } from "src/utils/contractResponseKiOnly"
import BypassLimitToggle from "./BypassLimitToggle"

const SectionScreen = (): JSX.Element => {
  const navigate = useNavigate()
  const { contractId, version } = useParams<{
    contractId: string
    version: string
  }>()
  const apiClient = useApiClient()
  const { request: userRequest } = useGetCurrentUser()

  const user = RD.isSuccess(userRequest) ? userRequest.data.user : undefined

  if (!user) {
    throw new Error("Could not get user details")
  }

  if (!contractId) {
    throw new Error("Contract ID is not provided in the URL")
  }

  if (!version) {
    throw new Error("Version ID is not provided in the URL")
  }

  const versionNumber = parseInt(version, 10)

  const { toastType, toastMessage, toastState, setAndTriggerToast } =
    useToastHandler()

  const { request: contractDataReq } = useGetContract(contractId)
  const isValidContract = RD.isSuccess(contractDataReq)
  const [contractData, setContractData] = useState<ContractResponse | null>(
    null,
  )

  const pbqaId = useMemo(
    () => contractData?.sections[0]?.pbqaId || null,
    [contractData],
  )

  const { request: kiQuoteRequest } = useGetKiQuote(pbqaId)
  const kiQuote = RD.isSuccess(kiQuoteRequest) ? kiQuoteRequest.data : null
  const [openLayerId, setOpenLayerId] = useState<number | null>(null)
  // still need to handle if version is more than 1 this state should be true
  // something like isMultiVersioned = contractDetailsreq.data.versions.length > 1
  // use that inside this useState below
  // const [ignoreEnergyLimits, setIgnoreEnergyLimits] = useState(isMultiVersioned)
  const [ignoreEnergyLimits, setIgnoreEnergyLimits] = useState(false)

  const { request: documentReq } = useGetPBQADocuments(pbqaId)

  useEffect(() => {
    if (
      RD.isSuccess(contractDataReq) &&
      contractDataReq.data.sections.length > 0
    ) {
      setOpenLayerId(contractDataReq.data.sections[0].sectionId)
      setContractData(contractDataReq.data)
    } else {
      setOpenLayerId(null)
    }
  }, [contractDataReq])

  const initialLayerFormFields = useMemo(() => {
    if (!RD.isSuccess(contractDataReq)) return {}

    return initializeLayerFormFields(contractDataReq.data.sections)
  }, [contractDataReq])

  const processLayerFormFields = (): { hasErrors: boolean } => {
    const newLayerFormFields: Record<string, LayerFormField> = {}

    for (const [layerId, layerFormField] of Object.entries(layerFormFields)) {
      const {
        total: totalAllocatedPremium,
        totalArray: totalAllocatedPremiumArray,
      } = calculateTotalAllocatedPremium(layerFormField.sections)

      newLayerFormFields[layerId] = processLayer({
        layerFormField,
        totalAllocatedPremium,
        totalAllocatedPremiumArray,
        ignoreEnergyLimits: isKi1618EnergyLayer(layerFormField)
          ? ignoreEnergyLimits
          : false,
        validateSectionFormField,
      })
    }

    const { updatedLayerIdsWithError, hasErrors } = updateLayerErrors(
      newLayerFormFields,
      layerIdsWithError,
      isLayerValid,
    )

    setLayerIdsWithError(updatedLayerIdsWithError)
    setLayerFormFields({ ...newLayerFormFields })

    return { hasErrors }
  }

  const [layerFormFields, setLayerFormFields] = useState<
    Record<string, LayerFormField>
  >(initialLayerFormFields)

  useEffect(() => {
    setLayerFormFields(initialLayerFormFields)
  }, [initialLayerFormFields])

  const [layerIdsWithError, setLayerIdsWithError] = useState<Set<number>>(
    new Set(),
  )

  const onSave = (): void => {
    const { hasErrors } = processLayerFormFields()
    if (!hasErrors) {
      updateSections()
    }
  }

  const onBack = (): void => {
    navigate(`../${REVIEW_CONTRACT_GENERAL_PATH}`)
  }

  const onSubmit = async (): Promise<void> => {
    const { hasErrors } = processLayerFormFields()
    const hasStampedSlip = hasStampedSlipBeenUploaded(contractData?.files)
    const contractHeaderValid = isContractHeaderValid(contractData?.header)

    if (hasErrors) {
      setAndTriggerToast(
        "error",
        "Please correct the errors in the form before submitting.",
      )
      return
    }

    if (!hasStampedSlip) {
      setAndTriggerToast(
        "error",
        "Stamped slip is required to complete the contract.",
      )
      return
    }

    if (!contractHeaderValid) {
      setAndTriggerToast(
        "error",
        "Contract header data is required to complete the contract.",
      )
      return
    }

    try {
      await updateSections()

      const contractData = await finalizeContract()
      navigate(`/contract/view/${contractId}/`, {
        state: { fromSubmit: true, contractData },
      })
    } catch (err) {
      handleSubmissionError(err)
    }
  }

  const handleSubmissionError = (err: unknown): void => {
    const defaultErrorMessage = "Failed to complete contract"

    if (axios.isAxiosError(err)) {
      const errorMessage = err.response?.data.message || defaultErrorMessage
      setAndTriggerToast("error", errorMessage)
    } else {
      setAndTriggerToast("error", defaultErrorMessage)
    }

    Sentry.captureException(err)
  }

  const finalizeContract = async (): Promise<ContractResponse> => {
    const response = await completeContract(
      apiClient,
      contractId,
      versionNumber,
      user.id,
    )

    return response.data
  }

  const updateSections = async (): Promise<void> => {
    if (contractData == null) {
      throw new Error("Cannot update contract sections without contract data.")
    }

    const updatedRequest = updateContractDataWithFormFields(
      contractData,
      layerFormFields,
    )
    try {
      await updateContractSections(
        apiClient,
        updatedRequest,
        contractId,
        versionNumber,
      )
      setAndTriggerToast("success", "Contract sections updated successfully")
    } catch (error) {
      setAndTriggerToast("error", "Failed to save contract sections")
      if (error instanceof Error) {
        Sentry.captureException(error)
      }
    }
  }

  const openLayer =
    (openLayerId !== null && layerFormFields[openLayerId]) || null

  const isEnergyLayer = isKi1618EnergyLayer(openLayer)

  return RD.match(
    contractDataReq,
    <Loading />,
    <Loading />,
    (contractData: ContractResponse) => {
      const header = {
        insured: contractData.header.insured,
        umr: contractData.contract.uniqueMarketReference,
        programmeRef: getKiProgrammeRef(contractData),
        aggWrittenPremium: contractData.header.aggregateWrittenPremium,
      }
      const layers = contractData.sections
      const contractSection = layers.find(
        section => section.sectionId === openLayerId,
      )

      if (!contractSection) {
        return <div>No contract section available.</div>
      }

      return (
        <>
          <RarcDefaultWarningBanner
            createdAt={kiQuote?.createdAt}
            journeyConfigId={
              isValidContract
                ? contractDataReq.data.sections[0].journeyConfigId
                : null
            }
          />
          <div className="flex h-full w-full">
            <div className="w-7/12 pl-6">
              <div className="flex flex-col items-start justify-center gap-6 p-6">
                <HeaderSection
                  insured={header.insured}
                  umr={header.umr}
                  programmeRef={header.programmeRef}
                />
                <div className="grid w-full grid-cols-12 gap-6">
                  <div className="col-span-5 flex flex-col gap-6">
                    {layers.map(layer => (
                      <LayerDisclosure
                        key={layer.sectionId}
                        layer={layer}
                        showError={layerIdsWithError.has(layer.sectionId)}
                        open={openLayerId === layer.sectionId}
                        onClick={() => setOpenLayerId(layer.sectionId)}
                      />
                    ))}
                  </div>

                  {openLayer && (
                    <div className="col-span-7 flex flex-col gap-y-6">
                      {isEnergyLayer ? (
                        <>
                          <ContractSectionEnergyLayer
                            openLayer={openLayer}
                            setLayerFormFields={setLayerFormFields}
                          />
                          <BypassLimitToggle
                            ignoreEnergyLimits={ignoreEnergyLimits}
                            setIgnoreEnergyLimits={setIgnoreEnergyLimits}
                          />
                        </>
                      ) : (
                        <ContractSectionLayer
                          openLayer={openLayer}
                          setLayerFormFields={setLayerFormFields}
                        />
                      )}
                      <div className="col-span-7">
                        <SectionFooter
                          onSave={onSave}
                          onBack={onBack}
                          onSubmit={onSubmit}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex h-full w-5/12 flex-grow pt-6 pr-12">
              {RD.match(
                documentReq,
                <Loading />,
                <Loading />,
                documents => (
                  <>
                    {documents.length > 0 && (
                      <DocumentContent document={documents[0]} />
                    )}
                  </>
                ),
                error => (
                  <ErrorMessage
                    message="Failed to load Documents"
                    error={error}
                  />
                ),
              )}
            </div>
          </div>
          <Toast.Toast
            type={toastType}
            message={toastMessage}
            open={toastState.open}
            onOpenChange={toastState.onOpenChange}
          />

          <ToastViewport />
        </>
      )
    },
    contractError => (
      <ErrorMessage message="Failed to load Contract" error={contractError} />
    ),
  )
}

export default SectionScreen
