import { FC, useCallback, useEffect, useId, useState } from "react"
import { Navigate, useNavigate, useParams } from "react-router-dom"

import { Button, Toast } from "@appia/ui-components"

import ScreenTemplate from "src/templates/ScreenTemplate"
import Loading from "src/components/Loading"
import ToastViewport from "src/components/ToastViewport"

import AwaitingBritCTA from "./AwaitingBritCTA"
import ProcessCTA from "./ProcessCTA"
import PolicySearchBar from "./PolicySearch"
import { EndorsementUMR, SelectedPolicyUMR } from "./UMR"
import EndorsementDocuments from "./Documents/EndorsementDocuments"
import PolicyDocuments from "./Documents/PolicyDocuments"

import ProcessModal from "./ProcessModal"
import ReferPUModal from "./ReferPUModal"
import GenericSuccessModal from "./SuccessModal"
import CompletionModal from "./CompletionModal"
import PolicyCreationModal from "./PolicyCreationModal"
import PolicyReferencesModal from "./PolicyReferencesModal"

import { Endorsement, Policy, searchPolicies } from "@appia/api"
import * as RD from "@appia/remote-data"
import * as Sentry from "@sentry/react"
import { useGetEndorsement } from "src/swr"

import { useLogPageView } from "src/amplitude"
import useDocumentTitle from "src/hooks/useDocumentTitle"
import { PageNameContext } from "src/contexts/PageNameContext"
import useApiClient from "src/contexts/ApiClientContext"
import { handleEndorsementCompletion } from "./handleEndorsementCompletion"

const PAGE_NAME = "Review endorsement"

type PageState =
  | { type: "default" }
  | { type: "process-modal" }
  | { type: "refer-pu-modal" }
  | { type: "completion-modal" }
  | {
      type: "generic-success-modal"
      showDashboardCta: boolean
      message: string
    }
  | { type: "policy-creation-modal" }

const ReviewScreen: FC = () => {
  const { id: endorsementId } = useParams<{ id: string }>()

  if (endorsementId === undefined) {
    throw new Error("Missing endorsementId in ReviewEndorsement screen")
  }

  useLogPageView({ pageName: PAGE_NAME })
  useDocumentTitle(PAGE_NAME)

  const navigate = useNavigate()
  const apiClient = useApiClient()

  const [policyWasDeselected, setDeselectedPolicy] = useState<boolean>(false)
  const [pageState, setPageState] = useState<PageState>({ type: "default" })

  const { request: endorsementRequest, update: updateEndorsement } =
    useGetEndorsement(endorsementId)

  const [completionReq, setCompletionReq] = useState<
    RD.RemoteData<Error, Endorsement>
  >(RD.NotAsked)

  const [policyReq, setPolicyReq] = useState<RD.RemoteData<Error, Policy>>(
    RD.NotAsked,
  )

  const selectPolicy = useCallback(
    async (umr: string): Promise<void> => {
      setPolicyReq(RD.Loading)

      try {
        const { data: policies } = await searchPolicies(apiClient, umr)

        if (policies.length > 0) {
          setPolicyReq(RD.Success(policies[0]))
        } else {
          setPolicyReq(
            RD.Failure(new Error(`No policies found for UMR: ${umr}`)),
          )
        }
      } catch (e) {
        Sentry.captureException(e)

        if (e instanceof Error) {
          setPolicyReq(RD.Failure(e))
        }
      }
    },
    [apiClient, setPolicyReq],
  )

  const [open, setOpen] = useState<boolean>(false)

  // Try to load a policy when the page loads, based on the endorsement's UMR
  useEffect(() => {
    if (
      policyWasDeselected ||
      !RD.isNotAsked(policyReq) ||
      !RD.isSuccess(endorsementRequest)
    ) {
      return
    }

    const umr = endorsementRequest.data.umr
    if (!umr) {
      return
    }

    selectPolicy(umr)
  }, [
    apiClient,
    endorsementRequest,
    policyReq,
    policyWasDeselected,
    selectPolicy,
  ])

  const [toastType, setToastType] = useState<Toast.ToastType>("success")
  const [toastMessage, setToastMessage] = useState<string>("")
  const toastState = Toast.useToastState()

  const [isPolicyReferencesModalOpen, setPolicyReferencesModalOpen] =
    useState(false)

  const endorsementHeadingId = useId()
  const policyHeadingId = useId()

  if (RD.isNotAsked(endorsementRequest) || RD.isLoading(endorsementRequest)) {
    return <Loading className="mt-4" />
  } else if (!RD.isSuccess(endorsementRequest)) {
    return <Navigate replace to="/404" />
  }

  const endorsement: Endorsement = endorsementRequest.data
  const policyReferences = endorsement.policyReferences

  const policy: Policy | null = RD.isSuccess(policyReq) ? policyReq.data : null

  const completeEndorsement = async (): Promise<void> => {
    await handleEndorsementCompletion(
      apiClient,
      endorsement.id,
      setCompletionReq,
      updateEndorsement,
      () => {
        setToastType("error")
        setToastMessage(
          "An error has occurred completing endorsement. Please check your network connection and try again",
        )
        toastState.triggerToast()
      },
    )

    setPageState({ type: "completion-modal" })
  }

  return (
    <PageNameContext.Provider value={PAGE_NAME}>
      <ScreenTemplate
        pageTitle={PAGE_NAME}
        layout={{
          type: "regular",
          backPath: "/endorsements",
          actionButton: (
            <div className="flex items-center gap-2">
              {
                <Button
                  label="View policy references"
                  disabled={policyReferences.length === 0}
                  onClick={() => setPolicyReferencesModalOpen(true)}
                  className="col-start-1"
                  style="outlined"
                  theme="night"
                  size="small"
                  stretch="space-around"
                />
              }
              {policy &&
                (policy.isBritFollow || policy.isStub) &&
                endorsement.status !== "blocked_awaiting_third_party" && (
                  <AwaitingBritCTA
                    endorsementId={endorsement.id}
                    policyId={policy?.policyId ?? null}
                    onSuccess={() => {
                      navigate("/endorsements", {
                        state: {
                          toast: {
                            type: "success",
                            message:
                              "Successfully updated the endorsement's status to Awaiting Brit",
                          },
                        },
                      })
                    }}
                    onFailure={() => {
                      setToastType("error")
                      setToastMessage(
                        "An error has occurred. Please check your network connection and try again",
                      )
                      toastState.triggerToast()
                    }}
                  />
                )}

              <ProcessCTA
                endorsementId={endorsement.id}
                endorsementStatus={endorsement.status}
                endorsementType={endorsement.type}
                policyId={policy?.policyId ?? null}
                onSuccess={async () => {
                  if (
                    endorsement.type === "notice" ||
                    endorsement.status === "accepted_by_decider"
                  ) {
                    completeEndorsement()
                  } else {
                    setPageState({ type: "process-modal" })
                  }
                }}
                onFailure={() => {
                  setToastType("error")
                  setToastMessage(
                    "An error has occurred. Please check your network connection and try again",
                  )
                  toastState.triggerToast()
                }}
              />
            </div>
          ),
        }}
      >
        <div className="grid h-full grid-flow-col grid-cols-2 grid-rows-[auto,auto,1fr] gap-x-4 gap-y-0">
          <section aria-labelledby={policyHeadingId} className="contents">
            <h2 className="sr-only" id={policyHeadingId}>
              Policy
            </h2>

            <PolicySearchBar
              endorsementUMR={endorsement.umr ?? ""}
              selectedPolicy={policy}
              onChangePolicy={newPolicy => {
                setPolicyReq(RD.Success(newPolicy))
              }}
              setOpen={setOpen}
              open={open}
            />

            <SelectedPolicyUMR
              standalone={false}
              policyIsBritFollow={policy?.isBritFollow === true}
              policyIsStub={policy?.isStub ?? false}
              policyUMR={policy?.umr ?? null}
              onCopySuccess={() => {
                setToastType("success")
                setToastMessage("Successfully copied UMR")
                toastState.triggerToast()
              }}
              onCopyFailure={() => {
                setToastType("error")
                setToastMessage("Failed to copy UMR")
                toastState.triggerToast()
              }}
              onDeselectPolicy={() => {
                setPolicyReq(RD.NotAsked)
                setDeselectedPolicy(true)
              }}
            />

            <PolicyDocuments
              policy={policy}
              onUploadFinalSlip={() => {
                setPageState({ type: "policy-creation-modal" })
              }}
            />
          </section>

          <section aria-labelledby={endorsementHeadingId} className="contents">
            <h2 className="sr-only" id={endorsementHeadingId}>
              Endorsement
            </h2>

            <div>{/* Empty grid area for layout purposes */}</div>

            <EndorsementUMR endorsementUMR={endorsement.umr} />

            <EndorsementDocuments endorsement={endorsement} />
          </section>
        </div>

        {pageState.type === "process-modal" && (
          <ProcessModal
            endorsementStatus={endorsement.status}
            isOpen
            onClose={() => setPageState({ type: "default" })}
            platformUrl={endorsement.platformUrl}
            source={endorsement.policyData?.source ?? null}
            onClickReferPU={() => setPageState({ type: "refer-pu-modal" })}
            onClickComplete={completeEndorsement}
          />
        )}

        {pageState.type === "refer-pu-modal" && (
          <ReferPUModal
            endorsementId={endorsementId}
            isOpen
            onClose={() => setPageState({ type: "default" })}
            onBack={() => setPageState({ type: "process-modal" })}
            onSuccess={async () => {
              await updateEndorsement()

              setPageState({
                type: "generic-success-modal",
                showDashboardCta: true,
                message: "Endorsement has successfully been sent to PU",
              })
            }}
          />
        )}

        {pageState.type === "completion-modal" && (
          <CompletionModal
            completionReq={completionReq}
            isOpen
            onClose={() => setPageState({ type: "default" })}
          />
        )}

        {pageState.type === "generic-success-modal" && (
          <GenericSuccessModal
            isOpen
            message={pageState.message}
            onClose={() => setPageState({ type: "default" })}
            showDashboardCta={pageState.showDashboardCta}
          />
        )}

        {pageState.type === "policy-creation-modal" && (
          <PolicyCreationModal
            isOpen
            onClose={() => setPageState({ type: "default" })}
            onSuccess={umr => {
              setPageState({
                type: "generic-success-modal",
                showDashboardCta: false,
                message: "Policy successfully created",
              })
              selectPolicy(umr)
            }}
          />
        )}

        {isPolicyReferencesModalOpen && (
          <PolicyReferencesModal
            isOpen={isPolicyReferencesModalOpen}
            onClose={() => setPolicyReferencesModalOpen(false)}
            policyReferences={policyReferences}
          />
        )}

        <Toast.Toast
          type={toastType}
          message={toastMessage}
          open={toastState.open}
          onOpenChange={toastState.onOpenChange}
        />

        <ToastViewport />
      </ScreenTemplate>
    </PageNameContext.Provider>
  )
}

export default ReviewScreen
