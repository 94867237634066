import { FC } from "react"

import {
  ArchiveIcon,
  BanIcon,
  BasePill,
  BoltIcon,
  CheckIcon,
  ClipboardListIcon,
  EditNoteIcon,
  FileDocsIcon,
  HourglassEmptyIcon,
  Pill,
  SpinnerIcon,
  UserAcceptedIcon,
  XIcon,
} from "@appia/ui-components"
import { EndorsementStatus } from "@appia/api"

export const endorsementStatusLabels: Record<EndorsementStatus, string> = {
  created: "Created",
  processing: "Processing",
  failed: "Failed",
  review_required: "Review required",
  blocked_awaiting_third_party: "Awaiting Brit",
  referred_to_decider: "Referred to PU",
  accepted_by_decider: "Accepted by PU",
  rejected_by_decider: "Rejected by PU",
  review_in_progress: "Review in progress",
  review_complete: "Completed",
  archived: "Archived",
  file_docs: "Docs to be filed",
}

const StatusPill: FC<{
  status: EndorsementStatus
}> = ({ status }) => {
  const label = endorsementStatusLabels[status]

  const statusPillMapping: Record<EndorsementStatus, JSX.Element> = {
    created: <Pill type="disabled" icon={<BoltIcon />} label={label} />,
    processing: <Pill type="neutral" icon={<SpinnerIcon />} label={label} />,
    failed: <Pill type="error" icon={<XIcon />} label={label} />,
    review_required: (
      <Pill type="pop" icon={<ClipboardListIcon />} label={label} />
    ),
    review_in_progress: (
      <Pill type="warning" icon={<EditNoteIcon />} label={label} />
    ),
    blocked_awaiting_third_party: (
      <BasePill
        bgColor="bg-otto-pop-900"
        textColor="text-white"
        icon={<HourglassEmptyIcon />}
        label={label}
      />
    ),
    referred_to_decider: (
      <Pill type="night" icon={<HourglassEmptyIcon />} label={label} />
    ),
    accepted_by_decider: (
      <BasePill
        bgColor="bg-otto-green-900"
        textColor="text-white"
        icon={<UserAcceptedIcon />}
        label={label}
      />
    ),
    rejected_by_decider: (
      <BasePill
        bgColor="bg-otto-red-700"
        textColor="text-white"
        icon={<BanIcon />}
        label={label}
      />
    ),
    review_complete: <Pill type="success" icon={<CheckIcon />} label={label} />,
    archived: <Pill type="disabled" icon={<ArchiveIcon />} label={label} />,
    file_docs: (
      <BasePill
        bgColor="bg-otto-yellow-500"
        textColor="text-otto-yellow-50"
        icon={<FileDocsIcon />}
        label={label}
      />
    ),
  }

  return statusPillMapping[status]
}

export default StatusPill
