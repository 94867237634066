import { FC } from "react"

import { EndorsementsSearchResult } from "@appia/api"

import { ChevronRightIcon, IconLink } from "@appia/ui-components"

import usePageName from "src/contexts/PageNameContext"
import { logButtonClick } from "src/amplitude"

const ActionCTA: FC<{
  endorsement: EndorsementsSearchResult
  tableLabel: string
}> = ({ endorsement, tableLabel }) => {
  const pageName = usePageName()

  const forAssuredName = endorsement.assuredName
    ? `for ${endorsement.assuredName}`
    : ""

  let label: string
  let ariaLabel: string
  let href: string

  switch (endorsement.status) {
    case "archived":
    case "created":
    case "failed":
    case "processing":
      return null

    case "blocked_awaiting_third_party":
    case "accepted_by_decider":
    case "review_required":
    case "review_in_progress":
    case "file_docs":
      label = "Review"
      ariaLabel = `Review endorsement ${forAssuredName}`
      href = `/endorsements/review/${endorsement.id}`
      break

    case "referred_to_decider":
    case "rejected_by_decider":
    case "review_complete":
      label = "View details"
      ariaLabel = `View details ${forAssuredName}`
      href = `/endorsements/view/${endorsement.id}`
      break
  }

  return (
    <IconLink
      href={href}
      onClick={() => {
        logButtonClick({
          buttonName: label,
          linkHref: href,
          containerName: tableLabel,
          pageName,
        })
      }}
      icon={<ChevronRightIcon />}
      label={ariaLabel}
      size={10}
      theme="bordered"
    />
  )
}

export default ActionCTA
