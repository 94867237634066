import { FC } from "react"
import { Card, ToggleRadioButtons } from "@appia/ui-components"

const BYPASS_ENERGY_OPTIONS = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
]

interface BypassLimitToggleProps {
  ignoreEnergyLimits: boolean
  setIgnoreEnergyLimits: (val: boolean) => void
}

const BypassLimitToggle: FC<BypassLimitToggleProps> = ({
  ignoreEnergyLimits,
  setIgnoreEnergyLimits,
}) => {
  return (
    <div className="flex justify-end">
      <Card className="flex w-1/3 items-center justify-center">
        <ToggleRadioButtons
          legend="Bypass Limit"
          options={BYPASS_ENERGY_OPTIONS}
          selectedValue={ignoreEnergyLimits ? "yes" : "no"}
          onSelect={value => setIgnoreEnergyLimits(value === "yes")}
        />
      </Card>
    </div>
  )
}

export default BypassLimitToggle
