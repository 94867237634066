import { useMemo } from "react"
import { KeyedMutator, default as useSWR } from "swr"
import * as RD from "@appia/remote-data"
import * as Sentry from "@sentry/react"
import useApiClient from "./contexts/ApiClientContext"

import type { CamelToSnake } from "./utils/typeLevel"

import {
  ApiClient,
  ApiError,
  ContractDetails,
  ContractResponse,
  ContractVersion,
  ContractsPipelineSearch,
  ContractsSearchQuery,
  Document,
  Endorsement,
  EndorsementsSearch,
  EndorsementsSearchQuery,
  KiQuote,
  Me,
  MinorSubClassResponse,
  MinorSubClassesRequest,
  OverliningContext,
  PBQAInfo,
  PBQAOverview,
  PBQASearch,
  PBQAUploadFileLimits,
  PBQAsSearchQuery,
  PolicyCreationUploadFileLimits,
  PolicyDocuments,
  Recipient,
  SpreadsheetData,
  Syndicate,
  Team,
  User,
  Webhook,
  WebhookList,
  getContractDetailsUrl,
  getContractUrl,
  getContractVersionsUrl,
  getCurrentUserUrl,
  getEndorsementDocumentsUrl,
  getEndorsementUrl,
  getKiQuoteUrl,
  getMinorAndSubClassesUrl,
  getOverliningContextUrl,
  getPBQADocumentsUrl,
  getPBQAInfoUrl,
  getPBQAOverviewUrl,
  getPBQAUploadFileLimitsUrl,
  getPURecipients,
  getPURecipientsUrl,
  getPolicyCreationUploadFileLimitsUrl,
  getPolicyDocumentsUrl,
  getSpreadsheetDataUrl,
  getSyndicatePartnersUrl,
  getTeamUrl,
  getTeamUsersUrl,
  getTeamsUrl,
  getUsersUrl,
  getWebhookUrl,
  listWebhooksUrl,
  searchContractsUrl,
  searchEndorsementsUrl,
  searchPBQAsUrl,
} from "@appia/api"
import axios, { AxiosRequestConfig } from "axios"

interface SWRResponse<A> {
  request: RD.RemoteData<ApiError, A>
  update: KeyedMutator<A>
}

type Fetcher<A> = (c: ApiClient) => Promise<A>

interface SWROptions {
  automaticallyRevalidate: boolean
  refreshIntervalMs?: number
  shouldRetryOnError: boolean
  skipErrorLog: boolean
  ignoreCasing: boolean
}

const defaultSWROptions: SWROptions = {
  automaticallyRevalidate: true,
  shouldRetryOnError: true,
  skipErrorLog: false,
  ignoreCasing: false,
}

export const useSWRFetcher = <A>(
  // If we pass `null` here, SWR won't make the API call. This lets us make
  // requests conditionally: https://swr.vercel.app/docs/conditional-fetching
  swrKey: string | null,
  userOptions?: Partial<SWROptions>,
  useCaseConversion = true,
  fetcher?: Fetcher<A>,
): SWRResponse<A> => {
  const apiClient = useApiClient(useCaseConversion)

  const options: SWROptions = { ...defaultSWROptions, ...(userOptions || {}) }
  const {
    automaticallyRevalidate,
    refreshIntervalMs,
    shouldRetryOnError,
    skipErrorLog,
    ignoreCasing,
  } = options

  let axiosConfig: AxiosRequestConfig | undefined = undefined
  if (ignoreCasing) {
    axiosConfig = {
      transformResponse: axios.defaults.transformResponse,
    }
  }

  const { data, error, mutate } = useSWR<A, ApiError>(
    swrKey,

    url =>
      fetcher
        ? fetcher(apiClient)
        : apiClient.get(url, axiosConfig).then(res => res.data),

    automaticallyRevalidate
      ? {
          shouldRetryOnError,
          refreshInterval: refreshIntervalMs,
        }
      : {
          revalidateIfStale: false,
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
          shouldRetryOnError,
        },
  )

  // SWR memoises its `data` and `error` fields to avoid causing unnecessary
  // re-renders, but because we're wrapping them in a RemoteData object we need
  // to do the same
  return useMemo(() => {
    let request: RD.RemoteData<ApiError, A> = RD.NotAsked

    if (error) {
      if (!skipErrorLog) {
        Sentry.captureException(error)
      }
      request = RD.Failure(error)
    } else if (swrKey === null) {
      request = RD.NotAsked
    } else if (!error && !data) {
      request = RD.Loading
    } else if (data) {
      request = RD.Success(data)
    }

    return { request, update: mutate }
  }, [data, error, mutate, skipErrorLog, swrKey])
}

// Helper function to construct URLSearchParams
const rawParamsToURLSearchParams = (
  rawParams: Record<
    string,
    null | undefined | string | string[] | number | boolean | boolean[]
  >,
): URLSearchParams => {
  const searchParams = new URLSearchParams()
  Object.entries(rawParams).forEach(([key, vals]) => {
    if (vals === null || vals === undefined) {
      return
    } else if (Array.isArray(vals)) {
      vals.forEach(val => {
        searchParams.append(key, val.toString())
      })
    } else if (typeof vals === "number") {
      searchParams.append(key, vals.toString())
    } else {
      searchParams.append(key, vals.toString())
    }
  })
  return searchParams
}

export const useGetSpreadsheetData = (
  id: Document["id"],
): SWRResponse<SpreadsheetData> => useSWRFetcher(getSpreadsheetDataUrl(id))

export const useSearchEndorsements = ({
  assuredName,
  order,
  orderBy,
  ownerId,
  page,
  pageSize,
  status,
  type,
  umr,
  updatedBy,
  groupClass,
}: Partial<EndorsementsSearchQuery>): SWRResponse<EndorsementsSearch> => {
  type RawParams = {
    [K in keyof EndorsementsSearchQuery as CamelToSnake<K>]?: EndorsementsSearchQuery[K]
  }

  const rawParams: RawParams = {
    assured_name: assuredName,
    order,
    order_by: orderBy,
    owner_id: ownerId,
    page,
    page_size: pageSize,
    status,
    type,
    umr,
    updated_by: updatedBy,
    group_class: groupClass,
  }

  const searchParams = rawParamsToURLSearchParams(rawParams)

  return useSWRFetcher(searchEndorsementsUrl(searchParams), {
    refreshIntervalMs: 10000,
  })
}

export const useGetEndorsementDocuments = (
  id?: string,
): SWRResponse<Document[]> =>
  useSWRFetcher(id ? getEndorsementDocumentsUrl(id) : null)

export const useGetPolicyDocuments = (
  id: string | null,
): SWRResponse<PolicyDocuments> =>
  useSWRFetcher(id ? getPolicyDocumentsUrl(id) : null)

export const useGetEndorsement = (id: string): SWRResponse<Endorsement> =>
  useSWRFetcher(getEndorsementUrl(id))

export const useSearchPBQAs = ({
  assuredName,
  brokerId,
  order,
  orderBy,
  ownerId,
  page,
  pageSize,
  policyId,
  status,
  umr,
  updatedBy,
}: Partial<PBQAsSearchQuery>): SWRResponse<PBQASearch> => {
  type RawParams = {
    [K in keyof PBQAsSearchQuery as CamelToSnake<K>]?: PBQAsSearchQuery[K]
  }

  const rawParams: RawParams = {
    assured_name: assuredName,
    broker_id: brokerId,
    order,
    order_by: orderBy,
    owner_id: ownerId,
    page,
    page_size: pageSize,
    policy_id: policyId,
    status,
    umr,
    updated_by: updatedBy,
  }

  const searchParams = rawParamsToURLSearchParams(rawParams)

  return useSWRFetcher(searchPBQAsUrl(searchParams), {
    refreshIntervalMs: 10000,
  })
}

export const useGetTeam = (id: string | null): SWRResponse<Team> =>
  useSWRFetcher(id && getTeamUrl(id))

export const useGetTeams = (): SWRResponse<{ teams: Team[] }> =>
  useSWRFetcher(getTeamsUrl)

export const useGetCurrentUser = (): SWRResponse<Me> =>
  useSWRFetcher(getCurrentUserUrl, { skipErrorLog: true })

export const useGetUsers = (
  teamId: Team["id"] | null = null,
): SWRResponse<{ users: User[] }> =>
  useSWRFetcher(teamId ? getTeamUsersUrl(teamId) : getUsersUrl)

export const useGetWebhook = (id: string): SWRResponse<Webhook> =>
  useSWRFetcher(getWebhookUrl(id))

export const useGetWebhooks = (): SWRResponse<WebhookList> =>
  useSWRFetcher(listWebhooksUrl)

export const useGetPBQAUploadFileLimits =
  (): SWRResponse<PBQAUploadFileLimits> =>
    useSWRFetcher(getPBQAUploadFileLimitsUrl)

export const useGetPBQADocuments = (
  id: string | null,
): SWRResponse<Document[]> => useSWRFetcher(id ? getPBQADocumentsUrl(id) : null)

export const useGetPBQAOverview = (id: string): SWRResponse<PBQAOverview> =>
  useSWRFetcher(getPBQAOverviewUrl(id))

export const useGetPBQAInfo = (id: string): SWRResponse<PBQAInfo> =>
  useSWRFetcher(getPBQAInfoUrl(id))

export const useGetKiQuote = (pbqaId: string | null): SWRResponse<KiQuote> =>
  useSWRFetcher(pbqaId ? getKiQuoteUrl(pbqaId) : null, {
    shouldRetryOnError: false,
  })

export const useGetOverliningContext = (
  id: string,
): SWRResponse<OverliningContext> =>
  useSWRFetcher(getOverliningContextUrl(id), {
    ignoreCasing: true,
  })

export const useGetPURecipients = (): SWRResponse<Recipient[]> => {
  const fetcher: Fetcher<Recipient[]> = async (apiClient: ApiClient) => {
    return getPURecipients(apiClient)
  }

  return useSWRFetcher(
    getPURecipientsUrl,
    { automaticallyRevalidate: false },
    true,
    fetcher,
  )
}

export const useGetPolicyCreationUploadFileLimits =
  (): SWRResponse<PolicyCreationUploadFileLimits> =>
    useSWRFetcher(getPolicyCreationUploadFileLimitsUrl)

export const useGetSyndicatePartners = (): SWRResponse<Syndicate[]> =>
  useSWRFetcher(getSyndicatePartnersUrl)

export const useSearchContracts = ({
  insured,
  versionStatus,
  uniqueMarketReference,
  brokingHouse,
  assignedTo,
  archived,
  unassigned,
  pageNumber,
  pageSize,
}: Partial<ContractsSearchQuery>): SWRResponse<ContractsPipelineSearch> => {
  type RawParams = {
    [K in keyof ContractsSearchQuery as CamelToSnake<K>]?: ContractsSearchQuery[K]
  }

  const rawParams: RawParams = {
    insured: insured,
    version_status: versionStatus,
    broking_house: brokingHouse,
    unique_market_reference: uniqueMarketReference,
    assigned_to: assignedTo,
    archived: archived,
    unassigned: unassigned,
    page_number: pageNumber,
    page_size: pageSize,
  }

  const searchParams = rawParamsToURLSearchParams(rawParams)
  return useSWRFetcher(searchContractsUrl(searchParams), {
    refreshIntervalMs: 10000,
  })
}

export const useGetContractVersions = (
  contractId: string,
): SWRResponse<ContractVersion[]> =>
  useSWRFetcher(getContractVersionsUrl(contractId))

export const useGetContract = (
  contractId: string,
): SWRResponse<ContractResponse> =>
  useSWRFetcher(getContractUrl(contractId), { refreshIntervalMs: 10000 })

export const useGetContractDetails = (
  contractId: string,
): SWRResponse<ContractDetails> =>
  useSWRFetcher(getContractDetailsUrl(contractId), { refreshIntervalMs: 10000 })

export const useGetMinorAndSubClasses = (
  params: MinorSubClassesRequest,
): SWRResponse<MinorSubClassResponse> =>
  useSWRFetcher(getMinorAndSubClassesUrl(params), {}, false)
