import { AxiosInstance, AxiosPromise } from "axios"
import { PBQAOverview } from "./Overview"
import type {
  KiJourneyType,
  PBQABrokerAnswer,
  PBQACountryAnswer,
  PBQADateAnswer,
  PBQADecimalAnswer,
  PBQAInceptionDateAnswer,
  PBQAInsuredAnswer,
  PBQAIntegerAnswer,
  PBQAOptionAnswer,
  PBQAOptionMultiAnswer,
  PBQAOverliningAnswer,
  PBQAPolicyReferenceAnswer,
  PBQAQuestion,
  PBQAStringAnswer,
  PBQASyndicateAnswer,
  PBQAUmrAnswer,
} from "./Survey"
import { Syndicate } from "../Syndicate"

export type KiRiskType =
  | "assisted_new_business"
  | "existing_business_follow"
  | "new_business_follow"
  | "nominated_lead_cargo"
  | "nominated_lead_commercial_director"
  | "nominated_lead_cyber"
  | "nominated_lead_financial_institutions"
  | "nominated_lead_marine_hull_and_war"
  | "nominated_lead_north_american_property"
  | "nominated_lead_other_property"
  | "nominated_lead_pi_us"
  | "nominated_lead_tech"
  | "contingency"
  | "energy_liability"

export type KiStatus = "expired" | "indication" | "placed" | "quoted"

export type KiClassOfBusiness =
  | "ambridge_cyber_tech"
  | "ambridge_excess_casualty"
  | "ambridge_general_liability"
  | "ambridge_liability_package_sir"
  | "ambridge_professional_indemnity_us"
  | "ambridge_property_package_sir"
  | "ambridge_small_na_liability"
  | "ambridge_terrorism"
  | "ambridge_transactional"
  | "british_cyber_attack_plus"
  | "cargo"
  | "casualty_treaty_lt_cat"
  | "casualty_treaty_lt_pro_rata"
  | "casualty_treaty_lt_risk"
  | "casualty_treaty_st_cat"
  | "casualty_treaty_st_risk"
  | "commercial_na_facilities"
  | "commercial_na_open_market"
  | "commercial_worldwide"
  | "contingency"
  | "cyber_british_cyber_attack_plus"
  | "cyber_privacy_tech"
  | "directors_officers_worldwide"
  | "energy_construction"
  | "energy_gom_wind"
  | "energy_liability"
  | "energy_midstream"
  | "energy_operational"
  | "energy_renewable"
  | "financial_institutions"
  | "flood"
  | "healthcare_liability"
  | "household_hv"
  | "household_na"
  | "hull"
  | "marine_liability"
  | "marine_war"
  | "nuclear"
  | "personal_accident"
  | "professional_indemnity_us"
  | "professional_liability_international"
  | "property_financial"
  | "property_liability_us_scgl"
  | "property_uk"
  | "scion_casualty"
  | "small_na_liability"
  | "specie"
  | "terrorism"
  | "transportation"

export type FacilityLineScope =
  | "in_scope"
  | "out_of_scope"
  | "referred"
  | "referred_in_scope"
  | "referred_out_of_scope"

export type KiBrokerAnswer = PBQABrokerAnswer["answer"]
export type KiCountryAnswer = PBQACountryAnswer["answer"]
export type KiDateAnswer = PBQADateAnswer["answer"]
export type KiInceptionDateAnswer = PBQAInceptionDateAnswer["answer"]
export type KiInsuredAnswer = PBQAInsuredAnswer["answer"]
export type KiOptionAnswer = PBQAOptionAnswer["answer"]
export type KiOptionMultiAnswer = PBQAOptionMultiAnswer["answer"]
export type KiOverliningAnswer = PBQAOverliningAnswer["answer"]
export type KiPolicyReferenceAnswer = PBQAPolicyReferenceAnswer["answer"]
export type KiStringAnswer = PBQAStringAnswer["answer"]
export type KiSyndicateAnswer = PBQASyndicateAnswer["answer"]
export type KiUmrAnswer = PBQAUmrAnswer["answer"]

export interface KiDecimalAnswer {
  answer: PBQADecimalAnswer["answer"]
  unit: PBQADecimalAnswer["unit"]
}

export interface KiIntegerAnswer {
  answer: PBQAIntegerAnswer["answer"]
  unit: PBQAIntegerAnswer["unit"]
}

export type KiAnswer =
  | KiBrokerAnswer
  | KiCountryAnswer
  | KiDateAnswer
  | KiDecimalAnswer
  | KiInceptionDateAnswer
  | KiInsuredAnswer
  | KiIntegerAnswer
  | KiPolicyReferenceAnswer
  | KiOptionAnswer
  | KiOptionMultiAnswer
  | KiStringAnswer
  | KiSyndicateAnswer
  | KiUmrAnswer

export type SyndicateSpecific<T> = Partial<Record<Syndicate["id"], T>>

export type KiQuoteData = Partial<
  Record<PBQAQuestion["id"], KiAnswer | SyndicateSpecific<KiAnswer>>
>

export interface KiQuote {
  assuredName: string | null
  createdAt: string
  capacity: number | null
  data: KiQuoteData
  expirationDate: string
  firmOrderNoticeRequestedAt: string | null
  groupClass: KiClassOfBusiness | null
  inceptionDate: string
  isIndication: boolean
  journeyType: KiJourneyType
  limit: number
  limitCurrency: string | null
  limitUsd: number | null
  lineSize: number | null
  policyReference: string | null
  premium: number | null
  premiumCurrency: string | null
  riskType: KiRiskType
  status: KiStatus
  syndicateLines: KiQuoteSyndicateLine[]
  facilityLine: KiQuoteFacilityLine | null
  // The quoteId is like an identifier for a group of quote objects; it is not
  // guaranteed to be unique
  quoteId: number | null
  // The quoteLineId is the unique identifier for each quote object
  quoteLineId: number
  validUntil: string | null
}

export interface KiQuoteSyndicateLine {
  firmOrderNoticeRequestedAt: string | null
  policyReference: string
  quotedLineSize: number
  syndicateCode: string
  syndicateName: string
  technicalPrice: number
}

export interface KiQuoteFacilityLine {
  triggerMarket: string | null
  triggerMarketShare: number | null
  orderPercentage: number | null
  scope: FacilityLineScope | null
  lineSize: number | null
  exclusionClauses: FacilityExclusionClauses | null
}

export interface FacilityExclusionClauses {
  contaminations: string | null
  cyber: string | null
  disease: string | null
  materials: string | null
  sanctions: string | null
  war: boolean | null
}

export const searchKiQuotesUrl = (pbqaId: string, query: string): string =>
  `/api/pbqa/${pbqaId}/search_ki?search_term=${encodeURIComponent(query)}`

export const searchKiQuotes = (
  client: AxiosInstance,
  pbqaId: string,
  query: string,
): AxiosPromise<KiQuote[]> => client.get(searchKiQuotesUrl(pbqaId, query))

export const getKiQuoteUrl = (pbqaId: PBQAOverview["pbqaId"]): string =>
  `/api/pbqa/${pbqaId}/ki_quote`

export const getKiQuote = (
  client: AxiosInstance,
  pbqaId: string,
): AxiosPromise<KiQuote> => client.get(getKiQuoteUrl(pbqaId))

export const recordKiQuote = (
  client: AxiosInstance,
  pbqaId: PBQAOverview["pbqaId"],
  quoteLineId: KiQuote["quoteLineId"],
): AxiosPromise =>
  client.put(`/api/pbqa/${pbqaId}/ki_quote`, {
    quote_line_id: quoteLineId,
  })

export const kiQuoteGroupClassLabels: Record<KiClassOfBusiness, string> = {
  ambridge_cyber_tech: "Ambridge Cyber Tech",
  ambridge_excess_casualty: "Ambridge Excess Casualty",
  ambridge_general_liability: "Ambridge General Liability",
  ambridge_liability_package_sir: "Ambridge Liability Package SIR",
  ambridge_professional_indemnity_us: "Ambridge Professional Indemnity US",
  ambridge_property_package_sir: "Ambridge Property Package SIR",
  ambridge_small_na_liability: "Ambridge Small NA Liability",
  ambridge_terrorism: "Ambridge Terrorism",
  ambridge_transactional: "Ambridge Transactional",
  british_cyber_attack_plus: "BCAP",
  cargo: "Cargo",
  casualty_treaty_lt_cat: "Casualty Treaty LT Cat",
  casualty_treaty_lt_pro_rata: "Casualty Treaty LT Pro Rata",
  casualty_treaty_lt_risk: "Casualty Treaty LT Risk",
  casualty_treaty_st_cat: "Casualty Treaty ST Cat",
  casualty_treaty_st_risk: "Casualty Treaty ST Risk",
  commercial_na_facilities: "Commercial NA Facilities",
  commercial_na_open_market: "Commercial NA Open Market",
  commercial_worldwide: "Commercial WW",
  contingency: "Contingency",
  cyber_british_cyber_attack_plus: "Cyber British Cyber Attack Plus",
  cyber_privacy_tech: "Cyber Privacy Tech",
  directors_officers_worldwide: "D&O WW",
  energy_construction: "Energy Construction",
  energy_gom_wind: "Energy Gom Wind",
  energy_liability: "Energy Liability",
  energy_midstream: "Energy Midstream",
  energy_operational: "Energy Operational",
  energy_renewable: "Energy Renewable",
  financial_institutions: "Financial Institutions",
  flood: "Flood",
  healthcare_liability: "Healthcare Liability",
  household_hv: "Household HV",
  household_na: "Household NA",
  hull: "Hull",
  marine_liability: "Marine Liability",
  marine_war: "Marine War",
  nuclear: "Nuclear",
  personal_accident: "Personal Accident",
  professional_indemnity_us: "Professional Indemnity US",
  professional_liability_international: "Professional Liability International",
  property_financial: "Property Financial",
  property_liability_us_scgl: "Property Liability US SCGL",
  property_uk: "Property UK",
  scion_casualty: "SCION Casualty",
  small_na_liability: "Small NA Liability",
  specie: "Specie",
  terrorism: "Terrorism",
  transportation: "Transportation",
}
