import { ApiClient, Endorsement, completeEndorsement } from "@appia/api"
import * as RD from "@appia/remote-data"
import * as Sentry from "@sentry/react"
import React from "react"

export const handleEndorsementCompletion = async (
  apiClient: ApiClient,
  endorsementId: Endorsement["id"],
  setCompletionReq: React.Dispatch<
    React.SetStateAction<RD.RemoteData<Error, Endorsement>>
  >,
  updateEndorsement: () => Promise<Endorsement | undefined>,
  onFailure: () => void,
): Promise<void> => {
  setCompletionReq(RD.Loading)

  try {
    const res = await completeEndorsement(apiClient, endorsementId)
    setCompletionReq(RD.Success(res.data))

    await updateEndorsement()
  } catch (e) {
    if (e instanceof Error) {
      setCompletionReq(RD.Failure(e))
      Sentry.captureException(e)
      onFailure()
    }
  }
}
