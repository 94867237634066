import {
  KiAnswer,
  KiBrokerAnswer,
  KiCountryAnswer,
  KiDateAnswer,
  KiDecimalAnswer,
  KiInceptionDateAnswer,
  KiInsuredAnswer,
  KiIntegerAnswer,
  KiOptionAnswer,
  KiOptionMultiAnswer,
  KiPolicyReferenceAnswer,
  KiQuote,
  KiStringAnswer,
  KiSyndicateAnswer,
  PBQAAnswer,
  PBQAAnswerReason,
  PBQABooleanAnswer,
  PBQABooleanQuestion,
  PBQABoundingBox,
  PBQABrokerAnswer,
  PBQABrokerQuestion,
  PBQACountryAnswer,
  PBQACountryQuestion,
  PBQADateAnswer,
  PBQADateQuestion,
  PBQADecimalAnswer,
  PBQADecimalQuestion,
  PBQAEEAAnswer,
  PBQAEEAQuestion,
  PBQAInceptionDateAnswer,
  PBQAInceptionDateQuestion,
  PBQAInsuredAnswer,
  PBQAInsuredQuestion,
  PBQAIntegerAnswer,
  PBQAIntegerQuestion,
  PBQAOptionAnswer,
  PBQAOptionMultiAnswer,
  PBQAOptionMultiQuestion,
  PBQAOptionQuestion,
  PBQAOverliningAnswer,
  PBQAOverliningQuestion,
  PBQAPolicyReferenceAnswer,
  PBQAPolicyReferenceQuestion,
  PBQAQuestion,
  PBQAStringAnswer,
  PBQAStringQuestion,
  PBQASurvey,
  PBQASyndicateAnswer,
  PBQASyndicateQuestion,
  PBQAUmrAnswer,
  PBQAUmrQuestion,
  Syndicate,
  SyndicateSpecific,
} from "@appia/api"

import { groupBy, toArray } from "src/utils/arrays"
import { OneOrMany, findInOneOrMany, mapOneOrMany } from "src/utils/oneOrMany"
import { isEmpty } from "src/utils/typeRefinements"
import { answersMatch } from "./answerUtils"

export type PBQAReviewState = Record<PBQAQuestion["id"], QuestionAnswersState>

/**
 * Link together matching types of questions, answers, and Ki quote data
 */
export interface QuestionAnswerStateBoolean {
  type: PBQABooleanQuestion["type"]
  question: PBQABooleanQuestion
  answer: PBQABooleanAnswer
  // Boolean question types can never have Ki quote data
  kiAnswer: undefined
}

export interface QuestionAnswerStateOverlining {
  type: PBQAOverliningQuestion["type"]
  question: PBQAOverliningQuestion
  answer: PBQAOverliningAnswer
  // Overlining question types can never have Ki quote data
  kiAnswer: undefined
}

export interface QuestionAnswerStateUMR {
  type: PBQAUmrQuestion["type"]
  question: PBQAUmrQuestion
  answer: PBQAUmrAnswer
  // UMR question types can never have Ki quote data
  kiAnswer: undefined
}

export interface QuestionAnswerStateEEA {
  type: PBQAEEAQuestion["type"]
  question: PBQAEEAQuestion
  answer: PBQAEEAAnswer
  // EEA question types can never have Ki quote data
  kiAnswer: undefined
}

type QuestionAnswerStateBase =
  | QuestionAnswerStateBoolean
  | {
      type: PBQABrokerQuestion["type"]
      question: PBQABrokerQuestion
      answer: PBQABrokerAnswer
      kiAnswer?: KiBrokerAnswer
    }
  | {
      type: PBQACountryQuestion["type"]
      question: PBQACountryQuestion
      answer: PBQACountryAnswer
      kiAnswer?: KiCountryAnswer
    }
  | {
      type: PBQADateQuestion["type"]
      question: PBQADateQuestion
      answer: PBQADateAnswer
      kiAnswer?: KiDateAnswer
    }
  | {
      type: PBQADecimalQuestion["type"]
      question: PBQADecimalQuestion
      answer: PBQADecimalAnswer
      kiAnswer?: KiDecimalAnswer
    }
  | {
      type: PBQAInceptionDateQuestion["type"]
      question: PBQAInceptionDateQuestion
      answer: PBQAInceptionDateAnswer
      kiAnswer?: KiInceptionDateAnswer
    }
  | {
      type: PBQAInsuredQuestion["type"]
      question: PBQAInsuredQuestion
      answer: PBQAInsuredAnswer
      kiAnswer?: KiInsuredAnswer
    }
  | {
      type: PBQAIntegerQuestion["type"]
      question: PBQAIntegerQuestion
      answer: PBQAIntegerAnswer
      kiAnswer?: KiIntegerAnswer
    }
  | {
      type: PBQAOptionQuestion["type"]
      question: PBQAOptionQuestion
      answer: PBQAOptionAnswer
      kiAnswer?: KiOptionAnswer
    }
  | {
      type: PBQAOptionMultiQuestion["type"]
      question: PBQAOptionMultiQuestion
      answer: PBQAOptionMultiAnswer
      kiAnswer?: KiOptionMultiAnswer
    }
  | {
      type: PBQAPolicyReferenceQuestion["type"]
      question: PBQAPolicyReferenceQuestion
      answer: PBQAPolicyReferenceAnswer
      kiAnswer?: KiPolicyReferenceAnswer
    }
  | {
      type: PBQAStringQuestion["type"]
      question: PBQAStringQuestion
      answer: PBQAStringAnswer
      kiAnswer?: KiStringAnswer
    }
  | {
      type: PBQASyndicateQuestion["type"]
      question: PBQASyndicateQuestion
      answer: PBQASyndicateAnswer
      kiAnswer?: KiSyndicateAnswer
    }
  | QuestionAnswerStateUMR
  | QuestionAnswerStateOverlining
  | QuestionAnswerStateEEA

type DistributeNonSyndicateSpecific<T> = T extends QuestionAnswerStateBase
  ? T & { isSyndicateSpecific: false }
  : never

/*
 * Questions that are not syndicate-specific: they have a single answer and
 * don't store a syndicate ID
 */
export type QuestionAnswerState =
  DistributeNonSyndicateSpecific<QuestionAnswerStateBase>

// Avoid distributing `undefined` to every member of `T`
type NoDistributedUndefined<T extends KiAnswer | undefined> = T extends KiAnswer
  ? SyndicateSpecific<T>
  : undefined

// Syndicate-specific answers always have a `syndicateId`; it can't be nullable
type RequiredSyndicateId<T extends PBQAAnswer> = Omit<T, "syndicateId"> & {
  syndicateId: string
}

type DistributeSyndicateSpecific<T> = T extends QuestionAnswerStateBase
  ? Omit<T, "answer" | "kiAnswer"> & {
      isSyndicateSpecific: true
      answer: RequiredSyndicateId<T["answer"]>[]
      kiAnswer: NoDistributedUndefined<T["kiAnswer"]>
    }
  : never

/**
 * Questions that are syndicate-specific: they can have multiple answers, one
 * per syndicate, and each answer stores a syndicate ID
 */
export type QuestionAnswerStateSyndicateSpecific =
  DistributeSyndicateSpecific<QuestionAnswerStateBase>

export type QuestionAnswersState =
  | QuestionAnswerState
  | QuestionAnswerStateSyndicateSpecific

export interface QuoteComparisonMatchModalData {
  questionId: string
  label: string
  quoteValue?: KiAnswer | Partial<Record<string, KiAnswer>>
  isMatch: boolean
}

const updateAnswerInState = <T extends QuestionAnswersState>(
  updatedAnswer: OneOrMany<PBQAAnswer>,
  state: T,
): T => ({
  ...state,
  answer: updatedAnswer,
})

const updateKiAnswerInState = <T extends QuestionAnswersState>(
  updatedKiAnswer: KiAnswer | SyndicateSpecific<KiAnswer> | undefined,
  state: T,
): T => ({
  ...state,
  kiAnswer: updatedKiAnswer,
})

const mkQuestionAnswerState = (
  type: QuestionAnswerState["type"],
  question: PBQAQuestion,
  answer: PBQAAnswer,
  kiAnswer?: KiAnswer,
): QuestionAnswerState =>
  // @ts-expect-error We can't prove to TS that the types of all these things
  // line up, so we have to take it on faith
  ({ type, isSyndicateSpecific: false, question, answer, kiAnswer })

const mkEmptyAnswer = (
  pbqaId: string,
  question: PBQAQuestion,
  syndicateId: PBQAAnswer["syndicateId"] = null,
  boundingBox: PBQABoundingBox | null = null,
): PBQAAnswer => ({
  accepted: question.defaultAccepted,
  answer: null,
  boundingBox: boundingBox,
  note: null,
  pbqaId,
  questionId: question.id,
  reasons: [],
  syndicateId,
  unit: null,
})

export const setAnswer = (
  state: QuestionAnswersState,
  answer: PBQAAnswer,
): QuestionAnswersState => {
  const updatedAnswer = mapOneOrMany(
    a => (a.syndicateId === answer.syndicateId ? answer : a),
    state.answer,
  )

  return updateAnswerInState(updatedAnswer, state)
}

/**
 * Initialise a PBQAReviewState based on existing answers from the API.
 *
 * The PBQAReviewState maps from question IDs to `QuestionAnswersState` objects.
 * This mapping is total: if there isn't an answer for a question yet, we'll
 * create an empty one. This a) simplifies things later and b) means that
 * switching between surveys preserves the answers for any shared questions.
 */
export const initialiseState = (
  pbqaId: string,
  // Each survey contains a set of questions and, possibly, some existing
  // answers that the user entered previously
  surveys: PBQASurvey[],
  // The syndicates associated with this PBQA
  syndicateIds: Syndicate["id"][],
  // The Ki quote associated with this PBQA, if any
  quote: KiQuote | null,
): PBQAReviewState => {
  type AnswerMapping = Record<PBQAQuestion["id"], OneOrMany<PBQAAnswer>>

  // First we get all the existing answers (if any) across all surveys.
  // Questions and answers can be shared across surveys, so we need to look at
  // all surveys rather than just the active one.
  const existingAnswers: Partial<AnswerMapping> = surveys
    .flatMap(s => s.answers)
    // Filter duplicates. It's OK to have multiple answers with the same
    // questionId as long as they have different syndicateIds
    .filter(
      (a, i, self) =>
        self.findIndex(
          item =>
            item.questionId === a.questionId &&
            item.syndicateId === a.syndicateId,
        ) === i,
    )
    .reduce<AnswerMapping>((acc, a) => {
      const prevAnswers: OneOrMany<PBQAAnswer> | undefined = acc[a.questionId]
      return {
        ...acc,
        [a.questionId]: Array.isArray(prevAnswers)
          ? [...prevAnswers, a]
          : prevAnswers
          ? [prevAnswers, a]
          : a,
      }
    }, {})

  // Next we get all the questions; again, we need to look at all surveys.
  const allQuestions = surveys
    .flatMap(s => s.questions)
    // Filter duplicates
    .filter((q, i, self) => self.findIndex(item => item.id === q.id) === i)

  // Build up the mapping from question ID to `QuestionAnswersState` objects.
  const state = allQuestions.reduce<PBQAReviewState>((acc, question) => {
    const { type, id, isSyndicateSpecific } = question

    // If this auestion is syndicate-specific, we will just take any existing
    // answers; initialising new ones is handled by the call to
    // `updateAnswersForSyndicates`. For non-syndicate-specific questions we
    // create a new answer if there isn't an existing one.
    const answer: OneOrMany<PBQAAnswer> = isSyndicateSpecific
      ? toArray(existingAnswers[id] || []).filter(
          a => a.syndicateId !== null && syndicateIds.includes(a.syndicateId),
        )
      : existingAnswers[id] || mkEmptyAnswer(pbqaId, question)

    const questionAnswersState: QuestionAnswersState = {
      type,
      isSyndicateSpecific,
      question,
      // @ts-expect-error We can't prove to TS that the `type`, `question` and
      // `answer` types all line up
      answer,
    }

    // Store the quote data for this question, if any.
    const stateWithKiAnswer: QuestionAnswersState = updateKiAnswerInState(
      quote?.data[id],
      questionAnswersState,
    )

    return { ...acc, [id]: stateWithKiAnswer }
  }, {})

  // Ensure we also create empty answers for syndicate-specific questions as
  // needed
  return updateAnswersForSyndicates(pbqaId, syndicateIds, state)
}

/**
 * Update the PBQA state when the list of syndicates changes (i.e. when the user
 * adds or removes one) by adding empty answers for syndicates that are new and
 * removing answers for syndicates we don't have any more
 */
export const updateAnswersForSyndicates = (
  pbqaId: string,
  syndicateIds: Syndicate["id"][],
  reviewState: PBQAReviewState,
): PBQAReviewState =>
  Object.entries(reviewState).reduce<PBQAReviewState>(
    (acc, [questionId, state]) => {
      if (!state.isSyndicateSpecific) {
        return { ...acc, [questionId]: state }
      }

      const existingAnswers: PBQAAnswer[] = state.answer

      const answersToKeep = existingAnswers.filter(
        a => a.syndicateId !== null && syndicateIds.includes(a.syndicateId),
      )

      const syndicateIdsWithAnswers = answersToKeep.map(a => a.syndicateId)

      const newAnswers = syndicateIds
        .filter(id => !syndicateIdsWithAnswers.includes(id))
        .map(id => mkEmptyAnswer(pbqaId, state.question, id))
        .map(answer =>
          answer.syndicateId
            ? copyKiAnswerToAnswer(
                mkQuestionAnswerState(
                  state.type,
                  state.question,
                  answer,
                  state.kiAnswer?.[answer.syndicateId],
                ),
              )
            : answer,
        )

      return {
        ...acc,
        [questionId]: updateAnswerInState(
          [...answersToKeep, ...newAnswers],
          state,
        ),
      }
    },
    {},
  )

/**
 * If the user selects a new Ki quote or changes survey, we need to reset their
 * state in several ways.
 *
 * For questions with layout="comparison", we keep the user's answers (which may
 * be the values Otto extracted). However the `accepted` and `note` answer
 * fields are no longer relevant, so we set them to `null`. Then we try to
 * automatically set the `accepted` field according to whether the new Ki quote
 * data matches the existing value or not.
 *
 * For non-comparison questions we totally reset the question state.
 */
export const storeKiQuoteAndAutoAcceptAnswers = (
  reviewState: PBQAReviewState,
  quote: KiQuote,
  activeSurvey: PBQASurvey,
  resetNonComparisonAnswers: boolean,
): PBQAReviewState => {
  const { questions } = activeSurvey

  return Object.entries(reviewState).reduce<PBQAReviewState>(
    (acc, [questionId, state]) => {
      const kiAnswer = quote.data[questionId]

      // If the question isn't in the list then it's from another survey, so we
      // leave it untouched
      if (questions.find(q => q.id === questionId) === undefined) {
        return { ...acc, [questionId]: state }
      }

      // These questions are a special case. Because they're not related to either
      // the survey or the Ki quote, they should never be reset or have Ki data
      // copied into them
      switch (state.question.type) {
        case "boolean":
          // We have to type match question because PBQABooleanQuestion doesn't have
          // the 'label' property.
          break
        default:
          switch (state.question.label.toLowerCase()) {
            case "umr":
            case "sanctions clause":
            case "cyber exposure":
            case "cyber war exclusion":
            case "radioactive contamination clause":
            case "communicable disease clause":
              return { ...acc, [questionId]: state }
          }
      }

      const isComparisonQuestion = state.question.layout === "comparison"

      // For comparison questions we reset the `note` and `accepted` fields
      if (isComparisonQuestion) {
        const resetAnswer = (a: PBQAAnswer): PBQAAnswer => ({
          ...a,
          note: null,
          accepted: answersMatch(
            a,
            state.isSyndicateSpecific && a.syndicateId
              ? (quote.data[a.questionId] as SyndicateSpecific<KiAnswer>)?.[
                  a.syndicateId
                ]
              : (quote.data[a.questionId] as KiAnswer),
          ),
        })

        const updatedAnswer = mapOneOrMany(resetAnswer, state.answer)

        return {
          ...acc,
          [questionId]: updateKiAnswerInState(
            kiAnswer,
            updateAnswerInState(updatedAnswer, state),
          ),
        }
      }

      // For non-comparison questions we copy across the `kiAnswer` value
      // (if there is one). If the `resetNonComparisonAnswers` flag is set, we
      // also reset the answer state.
      else {
        const maybeResetAnswer = (a: PBQAAnswer): PBQAAnswer =>
          resetNonComparisonAnswers
            ? mkEmptyAnswer(
                a.pbqaId,
                state.question,
                a.syndicateId,
                a.boundingBox,
              )
            : a

        const updatedAnswer = mapOneOrMany(maybeResetAnswer, state.answer)

        const updatedState = updateKiAnswerInState(
          kiAnswer,
          updateAnswerInState(updatedAnswer, state),
        )

        // Some questions can be overridden, meaning they are always editable.
        // (For example, the Eclipse Policy Reference question at time of
        // writing.) If this is the case, and the user has entered a value, we
        // should keep it rather than copying across the `kiAnswer`
        if (
          updatedState.question.canOverride &&
          updatedState.type !== "boolean" &&
          updatedState.type !== "overlining"
        ) {
          let allAnswerValuesEmpty: boolean
          switch (updatedState.type) {
            case "eea":
            case "decimal":
            case "integer":
              allAnswerValuesEmpty = toArray(updatedState.answer).every(a => {
                const answerValue = {
                  amount: a.answer || null,
                  unit: a.unit || null,
                }
                return isEmpty(answerValue)
              })
              break
            default:
              allAnswerValuesEmpty = toArray(updatedState.answer).every(a =>
                isEmpty(a.answer),
              )
              break
          }

          if (!allAnswerValuesEmpty) {
            return { ...acc, [questionId]: updatedState }
          }
        }

        // If the question was not overridable, or no value was entered,
        // continue to copy across the `kiAnswer`
        return {
          ...acc,
          [questionId]: copyKiAnswerToAnswers(updatedState),
        }
      }
    },
    {},
  )
}

/**
 * Of all the answers to questions with layout="comparison", how many match the
 * Ki quote data? Returns a value like [5, 11] meaning 5 answers out of 11 match
 */
export const countMatchingAnswersAmongComparisonQuestions = (
  state: PBQAReviewState,
  quote: KiQuote,
  activeSurvey: PBQASurvey,
): [number, number] => {
  const comparisonQuestionIds: PBQAQuestion["id"][] = activeSurvey.questions
    .filter(q => q.layout === "comparison")
    .map(q => q.id)

  const comparisonQuestionAnswers: PBQAAnswer[] = Object.values(state)
    .filter(s => comparisonQuestionIds.indexOf(s.question.id) !== -1)
    .flatMap(s => s.answer)

  const matchingAnswers = comparisonQuestionAnswers.filter(a =>
    answersMatch(
      a,
      a.syndicateId
        ? (quote.data[a.questionId] as SyndicateSpecific<KiAnswer>)?.[
            a.syndicateId
          ]
        : (quote.data[a.questionId] as KiAnswer),
    ),
  )

  return [matchingAnswers.length, comparisonQuestionAnswers.length]
}

/**
 * Transform a list of QuestionAnswersState (each with potentially multiple
 * answers) into a list of QuestionAnswerState (each with a single answer) by
 * picking out the answers that reference the given `syndicateId`
 */
export const flattenStatesForSyndicate = (
  states: QuestionAnswerStateSyndicateSpecific[],
  syndicateId: Syndicate["id"],
): QuestionAnswerState[] =>
  states.reduce<QuestionAnswerState[]>((acc, state) => {
    const matchingAnswer = findInOneOrMany<(typeof state)["answer"][0]>(
      a => a.syndicateId === syndicateId,
      state.answer,
    )

    return matchingAnswer
      ? [
          ...acc,
          mkQuestionAnswerState(
            state.type,
            state.question,
            matchingAnswer,
            state.kiAnswer?.[matchingAnswer.syndicateId],
          ),
        ]
      : acc
  }, [])

/**
 * Copy the value of a Ki answer into multiple PBQA answers
 */
export const copyKiAnswerToAnswers = <State extends QuestionAnswersState>(
  state: State,
): State => {
  const updatedAnswer = state.isSyndicateSpecific
    ? state.answer.map(a =>
        copyKiAnswerToAnswer(
          mkQuestionAnswerState(
            state.type,
            state.question,
            a,
            state.kiAnswer?.[a.syndicateId],
          ),
        ),
      )
    : copyKiAnswerToAnswer(state)

  return { ...state, answer: updatedAnswer }
}

/**
 * Copy the value of a Ki answer into a PBQA answer
 */
export const copyKiAnswerToAnswer = <State extends QuestionAnswerState>(
  state: State,
): State["answer"] => {
  switch (state.type) {
    // These question types can never have Ki answers
    case "boolean":
    case "overlining":
    case "eea":
    case "umr":
      return state.answer
    case "broker": {
      const { kiAnswer } = state
      return { ...state.answer, answer: kiAnswer ?? null }
    }
    case "syndicate": {
      const { kiAnswer } = state
      return { ...state.answer, answer: kiAnswer ?? null }
    }
    case "country":
    case "date":
    case "inception_date":
    case "insured":
    case "string":
    case "policy_reference":
    case "option": {
      const { kiAnswer } = state
      return {
        ...state.answer,
        answer: kiAnswer ?? null,
      }
    }
    case "option_multi": {
      const { kiAnswer } = state
      return {
        ...state.answer,
        answer: kiAnswer ?? null,
      }
    }
    case "decimal":
    case "integer": {
      const { kiAnswer } = state
      return {
        ...state.answer,
        answer: kiAnswer?.answer ?? null,
        unit: kiAnswer?.unit ?? null,
      }
    }
  }
}

/**
 * Validate the given `accepted` and `reasons` data to see if the user still
 * needs to provide a note
 */
export type NoteState = "notAccepted" | "missingNote" | "noError"

export const requiresNote = (
  accepted: boolean | null,
  reasons: PBQAAnswerReason[] | null,
): NoteState => {
  if (accepted === null) {
    return "notAccepted"
  }

  if (accepted === false && (reasons || []).length === 0) {
    return "missingNote"
  }

  return "noError"
}

/**
 * Group together adjacent QuestionAnswerState objects that have the same
 * `question.layout` value
 */
export interface QuestionLayoutGroup {
  states: QuestionAnswerState[]
  layout: PBQAQuestion["layout"]
}

export const groupQuestionStatesByLayout = (
  qs: QuestionAnswerState[],
): QuestionLayoutGroup[] => {
  const groups = groupBy(q => q.question.layout, qs)
  return groups.map(g => ({ states: g.items, layout: g.tag }))
}

/**
 * Group together adjacent QuestionAnswersState objects that have the same
 * `question.isSyndicateSpecific` value
 */
export type QuestionSyndicateSpecificityGroup =
  | {
      states: QuestionAnswerStateSyndicateSpecific[]
      isSyndicateSpecific: true
    }
  | {
      states: QuestionAnswerState[]
      isSyndicateSpecific: false
    }

export const groupQuestionStatesBySyndicateSpecificity = (
  qs: QuestionAnswersState[],
): QuestionSyndicateSpecificityGroup[] => {
  const groups = groupBy(q => q.question.isSyndicateSpecific, qs)
  // @ts-expect-error We can't easily prove to TS that the types line up here
  return groups.map(g => ({ states: g.items, isSyndicateSpecific: g.tag }))
}

function isPBQAAnswer(answer: unknown): answer is PBQAAnswer {
  return !!answer && typeof answer === "object" && "answer" in answer
}

export const createQuoteComparisonMatchModalData = (
  kiQuote: KiQuote,
  state: PBQAReviewState,
  activeSurvey: PBQASurvey,
): QuoteComparisonMatchModalData[] => {
  return activeSurvey.questions
    .filter(
      question =>
        question.layout === "comparison" ||
        (question.layout === "question" &&
          ["Leader share", "Nominated Syndicate share"].includes(
            (question as Exclude<PBQAQuestion, PBQABooleanQuestion>).label,
          )),
    )
    .map(question => {
      const pbqaQuestion = question as Exclude<
        PBQAQuestion,
        PBQABooleanQuestion
      >
      const answer = state[pbqaQuestion.id]?.answer
      const quoteValue = kiQuote.data[pbqaQuestion.id]
      const isMatch = isPBQAAnswer(answer)
        ? answersMatch(answer, quoteValue as KiAnswer)
        : false

      return {
        questionId: pbqaQuestion.id,
        label: pbqaQuestion.label,
        quoteValue,
        isMatch,
      }
    })
}
